import { useRef, useState, useEffect } from "react"
import { useAddNewUserMutation } from "../features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import useTitle from "../hooks/useTitle"
import useAuth from "../hooks/useAuth"
import '../multiForms.css'

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,100}$/

const NewUserForm = () => {
    useTitle('Volleysub')

    const {role, isSVL} = useAuth()

    useEffect(() => {
        if(role === "Player") {
            navigate('/dash/profile', { replace: true})
        }
    })
    useEffect(() => {
        if(isSVL) {
            navigate('/dash/profile', { replace: true})
        }
    })


    const userRef = useRef()
    //const errRef = useRef()
    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])



    const navigate = useNavigate()
    //const dispatch = useDispatch()

    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(["SVL"])
    const [firstName, setFirstName] = useState("")
    const [email, setEmail] = useState('')
    const [region, setRegion] = useState('')

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess) {
            setUsername("")
            setPassword("")
            setRoles("")
            setFirstName("")
            setEmail("")
            setRegion("")
            navigate('/dash/profile')
        }
    }, [isSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)
    const onFirstNameChanged = e => setFirstName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onRegionChanged = e => setRegion(e.target.value)


    const canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading

    //const [login ] = useLoginMutation()

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ username, password })
        }
        // try {
        //     const { accessToken } = await login({ username, password }).unwrap()
        //     dispatch(setCredentials({ accessToken }))
        //     setUsername('')
        //     setPassword('')
        //     navigate('/dash/profile')
        // } catch (err) {
        //     if (!err.status) {
        //         isError('No Server Response');
        //     } else if (err.status === 400) {
        //         isError('Missing Username or Password');
        //     } else if (err.status === 401) {
        //         isError("unauthorized");
        //     } else {
        //         isError(err.data?.message);
        //     }
        //     errRef.current.focus();
        // }
    }

    const errClass = isError ? "errmsg" : "offscreen"


    const content = (
        <>
        <div className="PlayerSignUpFormContainer">
            <p className={errClass}>{error?.data?.message}</p>
            <h1 className="PlayerSignUpTitle">Create New SVL</h1>
            <form className="PlayerSignUpForm" onSubmit={onSaveUserClicked}>
                <label>
                    Username 
                <input
                    id="username"
                    name="username"
                    type="text"
                    ref={userRef}
                    autoComplete="off"
                    value={username}
                    onChange={onUsernameChanged}
                    required
                />
                </label>

                <label>
                    Password
                <input
                    id="password"
                    name="password"
                    type="password"
                    ref={userRef}
                    value={password}
                    onChange={onPasswordChanged}
                    required
                />
                </label>
                <label>
                    Name
                <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={firstName}
                    onChange={onFirstNameChanged}
                    required
                />
                </label>
                <label>
                    Email 
                <input
                    id="Email"
                    name="Email"
                    type="email"
                    ref={userRef}
                    value={email}
                    onChange={onEmailChanged}
                    required
                />
                </label>

                <label>
                State
                    <select 
                        value={region}
                        onChange={onRegionChanged}
                        required>
                        <option value=""></option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                </label>
                <button className="FormSubmitButton">Create New SVL</button>
            </form>
            </div>
        </>
    )

    return content
}
export default NewUserForm