import { useParams } from 'react-router-dom'
import EditTeamForm from './EditTeamForm'
import { useGetTeamsQuery } from './TeamApiSlice'
import { useGetLeaguesQuery } from '../leagues/leagueApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditTeam = () => {
    useTitle('Volleysub: Edit Team')

    const { id } = useParams()

    const { team } = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            team: data?.entities[id]
        }),
    })

    const { league } = useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            league: data?.entities[id]
        }),
    })

    if (!team) return <PulseLoader color={"#FFF"} />

    const content = <EditTeamForm team={team} leagues={league} />

    return content
}
export default EditTeam