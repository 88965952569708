import { store } from '../../app/store'
import { notesApiSlice } from '../notes/notesApiSlice'
import { sessionsApiSlice } from '../sessions/sessionApiSlice';
import { usersApiSlice } from '../users/usersApiSlice';
import { leaguesApiSlice } from '../leagues/leagueApiSlice';
import { teamsApiSlice } from '../teams/TeamApiSlice';
import { newsletterApiSlice } from '../newsletter/newsletterApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(notesApiSlice.util.prefetch('getNotes', 'notesList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(sessionsApiSlice.util.prefetch('getSessions', 'sessionsList', { force: true }))
        store.dispatch(leaguesApiSlice.util.prefetch('getLeagues', 'leaguesList', { force: true }))
        store.dispatch(teamsApiSlice.util.prefetch('getTeams', 'teamsList', { force: true }))
        store.dispatch(newsletterApiSlice.util.prefetch('getNewsletter', 'newsletterList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch
