
const LeagueRulesCSixes = () => {
    return (
        <>
            <div>
                <details>
                    <summary className="league-title">League Rules - C Sixes</summary>
                        <div className="league-rules">
                        <ul>
                            <li>Teams must have at least four players to play with at least 2 men and 2 women</li>
                            <li>No more than four men are allowed on the court for a team at any time</li>
                            <li>No jump serving</li>
                            <li>When attacking the ball, back row players must be at least 10 feet from the net or remain grounded</li>
                            <li>Double contacts on sets are allowed</li>
                        </ul>
                        </div>
                </details>
            </div>
        </>
    )
}

export default LeagueRulesCSixes