import NewLeagueForm from './NewLeagueForm'
import { useGetSessionsQuery } from '../sessions/sessionApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewLeague = () => {
    useTitle('Volleysub: New League')

    const { sessions } = useGetSessionsQuery("sessionsList", {
        selectFromResult: ({ data }) => ({
            sessions: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!sessions?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewLeagueForm sessions={sessions} />

    return content
}
export default NewLeague