import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutPage from "./Checkout"
import StripeLogo from "../../stripe.png"

const stripePromise = loadStripe('pk_test_51NQuKmJMCR3lJxF6KZYw3MDFoB7dzlIaEEwpUM3c1dxaWzLb0sov9nKvow3xfFahis5TzMrJQ210ozKH2aS62pHJ00YjmLNABZ')

const CheckoutForm = () => {
    const leagueName = sessionStorage.leagueName
    const teamName = sessionStorage.teamName
    const amount = sessionStorage.amount
    const volleysubFee = sessionStorage.volleysubFee 
    const cash = sessionStorage.cash

    const cashOnly = () => {
        if (cash === "true") {
            return (<>
            <h5>League Amount : ${amount} - Due in Cash at time of first game</h5>
            <h5>Volleysub Processing Fee @ $5/player : ${volleysubFee}</h5>
            <h5><b>Total Due at Registration :</b> ${parseInt(volleysubFee)}</h5>
            </>)
        } else {
            return(<>
            <h5>League Amount : ${amount}</h5>
            <h5>Volleysub Processing Fee @ $5/player : ${volleysubFee}</h5>
            <h5><b>Total:</b> ${parseInt(amount) + parseInt(volleysubFee)}</h5>
            </>)
        }
    }

    //if (isLoading) return <PulseLoader color={"#FFF"} />


    return (
        <>
        <div className="checkout-page">
        <h2 className="league-title">Checkout</h2>
        <h4>{leagueName}</h4>
        <h4>{teamName}</h4>
        <div className="checkout-fees">
            {cashOnly()}
        </div>
        <h4 className="league-title">Payment Details</h4>
        <div className="cc-payment-form">
        <Elements stripe={stripePromise}>
            <CheckoutPage />
        </Elements>
        </div>
        <h5 className="stripe-creds">Checkout powered by <img alt="Stripe" className="stripe-logo" src={StripeLogo}/></h5>
        </div>
        </>
    )
}

export default CheckoutForm