import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import '../multiForms.css';

const VSMultiContact = () => {

    const {firstName, lastName, email} = useAuth();
    const [body, setBody] = useState("");

    const onChangedBody = e => setBody(e.target.value)

    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        window.Email.send({
            SecureToken : "92946103-4f3b-498c-b10d-80264d922482",
            To : "csheehan@volleysub.com",
            From : email,
            Subject : `Volleysub Player Contact Form - ${firstName} ${lastName}`,
            Body : body
        }).then(
            navigate('../playersent')
        );
    }

    return(
        <div>
            <div className="MultiContactFormContainer">
                <div className="MultiContactFormTitle">Contact Volleysub</div>
                <form className="MultiContactForm" onSubmit={handleSubmit}>
                <label className="MultiContactTextareaLabel">
                Let us know any questions you have or how we can improve Volleysub:
                    <textarea 
                        className="MultiContactTextarea"
                        value={body}
                        onChange={onChangedBody}
                        required
                        />
                </label>             
                <input className="FormSubmitButton" type="submit"/>
                </form>
            </div>
        </div>
    );
}

export default VSMultiContact;