import { useNavigate } from "react-router-dom"

const WaitlistSuccess = () => {

    const teamName = sessionStorage.teamName
    const leagueName = sessionStorage.leagueName
    const id = sessionStorage.leagueId


    const navigate = useNavigate()

    const backToLeagues = () => {
        navigate(`/dash/leagues/${id}/teams`)
    }

    return (
        <>
        <h2 className="league-title">Signup Successful!</h2>
        <div className="success-details">
        <h4>Thank you, {teamName}, for joining the waitlist for {leagueName}. The Volleysub team will notify you if an opening becomes available. </h4>
        </div>
        <button onClick={backToLeagues} className="session-update-button">Return to Standings</button>
        </>
    )
}

export default WaitlistSuccess