import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js"
import { useAddNewTeamMutation } from "./TeamApiSlice";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CheckoutPage = () => {

    const stripe = useStripe();
    const elements = useElements();
    const amount = sessionStorage.amount * 100
    const cash = sessionStorage.cash
    const teamName = sessionStorage.teamName
    const svl = sessionStorage.svl
    const leagueId = sessionStorage.leagueId
    const wins = 0;
    const losses = 0;
    let paid = false
    const {username} = useAuth()
    const user = username
    const [addNewTeam] = useAddNewTeamMutation()
    const navigate = useNavigate()

    const amountToCharge = () => {
        if (cash === "true") {
            return sessionStorage.volleysubFee * 100
        } else {
            return sessionStorage.amount * 100 + (sessionStorage.volleysubFee * 100)
        }
    }

    let content
    
    const handlePayment = async(e) => {
        e.preventDefault()
        const response = await axios.post(
            'https://volleysub-api.onrender.com/payment-intent',
            {
                amount: amountToCharge(),
                currency: 'usd',
                svl: `${svl} - ${amount}` ,
            },
        )
        try {if (response.status === 200){
            const confirmPayment = await stripe.confirmCardPayment(
                response.data,
                {
                    payment_method: {
                        card: elements.getElement(CardNumberElement)
                    }
                }
            )
        if(confirmPayment.paymentIntent.status === "succeeded"){
            console.log('payment confirmed')
            paid = true
            await addNewTeam({ league: leagueId, user, teamName, wins, losses, paid })
            sessionStorage.setItem("confirmationId", confirmPayment.paymentIntent.id)
            navigate(`../success`)
            } 
        }
        }catch (error) {
            console.log(error)
            navigate(`../decline`)
        }
    }
    

        content = (
        <form onSubmit={handlePayment}>
            <h5>Card Number:</h5> <CardNumberElement />
            <h5>Expiration Date:</h5> 
            <CardExpiryElement />
            <h5>CVC Number:</h5> 
            <CardCvcElement />
            <div className="checkout-button-container">
            <button className="checkout-pay-button" type="submit">Submit</button>
            </div>
        </form>
    )
    return content
}

export default CheckoutPage




