import { useGetTeamsQuery } from './TeamApiSlice'
import { memo } from 'react'
import useAuth from '../../hooks/useAuth'
import { useUpdateTeamMutation } from './TeamApiSlice'


const Waitlist = () => {


    const leagueId = sessionStorage.leagueId
    const {isSVL, isAdmin} = useAuth()

    const [updateTeam] = useUpdateTeamMutation()


    const { teams }  = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            teams: data?.entities
        }),
    }) 


    const filteredTeams = Object.values(teams).filter((league) => league.league === leagueId && league.waitlist === true)
    const sortedArray = filteredTeams.sort((a,b) => (a.id > b.id) ? 1 : -1)
    const team = sortedArray.map((teamName) => teamName.teamName)
    const teamId = sortedArray.map((teamName) => teamName.id)


    const promoteTeam = async (e) => {
        await updateTeam({ id: teamId, teamName: team[0], wins: 0, losses: 0, paid: true, waitlist: false })
    }

    const waitlistEntries = team.map((t) => <li>{t} {(isSVL || isAdmin) && <button className='promote-button' onClick={promoteTeam}>Promote</button>}</li>)

        const waitlistedTeams = () => {
                if (team.length < 1) {
                return (
                    <div className='waitlist-subheader'>
                        <h4>No Waitlisted Teams</h4>
                    </div>
                )
            } else {
                return (
                    <>
                        <div className='waitlist-subheader'>
                            <h4>Waitlisted Teams will be contacted in the order they sign up</h4>
                        </div>
                        <div className='waitlist'>
                            <ol>
                                {waitlistEntries}
                            </ol>
                        </div>
                    </>
                )
            }
        }


        return (
            <>
                {waitlistedTeams()}
            </>
        )


}

const memoizedWaitlist = memo(Waitlist)

export default memoizedWaitlist
