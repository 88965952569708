import React from 'react'
import { useGetUsersQuery } from '../features/users/usersApiSlice'
import PlayerInfoTemplate from './PlayerInfoTemplate'

const PlayerInfo = () => {

    const {
        data: users
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const content = () => {
    const { ids } = users
    const tableContent = ids?.length && ids.map(userId => <PlayerInfoTemplate key={userId} userId={userId} />)
    if (!ids) {
        return <p>Issue accessing player info. Please log out and log back in.</p>
    } else {
    return tableContent
        }
    }

    return (
    <>
    <h2 className='league-title'>My Info</h2>
        <p>{content()}</p>
    </>
    )
}

export default PlayerInfo