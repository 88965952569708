import NewTeamForm from './NewTeamForm'
import { useGetLeaguesQuery } from '../leagues/leagueApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewTeam = () => {
    useTitle('Volleysub: New Team')

    const { leagues } = useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            leagues: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!leagues?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewTeamForm leagues={leagues} />

    return content
}
export default NewTeam