import React from "react";
import '../index.css';
import Logo2 from '../Favicon Transparent.ico';
import { Link } from "react-router-dom";

const VSMultiHeader = () => {

return (
    <div className="multi-header">
        <Link className="multi-header-logo" to="/"><img className="multi-header-logo" src={Logo2} alt="logo" /> </Link>
        <h1 className="vs-header-text">VOLLEYSUB</h1> 
    </div>
);
}

export default VSMultiHeader;