import { useParams } from 'react-router-dom'
import EditLeagueForm from './EditLeagueForm'
import { useGetLeaguesQuery } from './leagueApiSlice'
import { useGetSessionsQuery } from '../sessions/sessionApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditLeague = () => {
    useTitle('Volleysub: League Home')

    const { id } = useParams()


    const { league } = useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            league: data?.entities[id]
        }),
    })

    const { sessions } = useGetSessionsQuery("sessionsList", {
        selectFromResult: ({ data }) => ({
            sessions: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!league || !sessions?.length) return <PulseLoader color={"#FFF"} />


    // if (!isSVL && !isAdmin) {
    //     if (league.username !== username) {
    //         return <p className="errmsg">No access</p>
    //     }
    // }

    const content = <EditLeagueForm league={league} sessions={sessions} />

    return content
}
export default EditLeague