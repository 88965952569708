import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth  from "../../hooks/useAuth"
import { useSendLogoutMutation } from "../auth/authApiSlice"

const USER_REGEX = /^[A-z0-9.!@#$%^_+{}:;"'<>/?~`&*()-]{3,50}$/
const PWD_REGEX = /^[A-z0-9.!@#$%^_+{}:;"'<>/?~`&*()-]{4,100}$/

const EditUserForm = ({ user }) => {

    const { isSVL, isAdmin, username } = useAuth()

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const [sendLogout] = useSendLogoutMutation()

    const navigate = useNavigate()

    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [roles, setRoles] = useState(user.roles)
    const [address, setAddress] = useState(user.address)
    const [city, setCity] = useState(user.city)
    const [region, setRegion] = useState(user.region)
    const [sub, setSub]= useState(user.sub)
    const [hitterSkillLevel, setHitterSkillLevel] = useState(user.hitterSkillLevel)
    const [passerSkillLevel, setPasserSkillLevel] = useState(user.passerSkillLevel)
    const [setterSkillLevel, setSetterSkillLevel] = useState(user.setterSkillLevel)

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])


    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setPassword('')
            setRoles([])
            navigate('/dash/playerinfo')
        }
    }, [isSuccess, isDelSuccess, navigate])


    const onPasswordChanged = e => setPassword(e.target.value)
    const onFirstNameChanged = e => setFirstName(e.target.value)
    const onLastNameChanged = e => setLastName(e.target.value)
    const onAddressChanged = e => setAddress(e.target.value)
    const onCityChanged = e => setCity(e.target.value)
    const onRegionChanged = e => setRegion(e.target.value)
    const onSubChanged = () => setSub(prev => !prev)

    const onHitterSkillLevelChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setHitterSkillLevel(values)
    }

    const onPasserSkillLevelChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setPasserSkillLevel(values)
    }

    const onSetterSkillLevelChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setSetterSkillLevel(values)
    }

    const onSaveUserClicked = async (e) => {
            if (password) {
                await updateUser({ id: user.id, username: user.username, firstName, password, roles, address, city, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel, active: user.active, lastName, email: user.email, gender: user.gender })
            } else if (isAdmin) {
                await updateUser({ id: user.id, username: user.username, firstName, roles, address, city, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel, active: user.active, lastName: user.lastName, email: user.email, gender: user.gender })
            } else {
                await updateUser({ id: user.id, username, firstName, roles, address, city, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel, active: user.active, lastName, email: user.email, gender: user.gender })
            }
    }

    const onDeleteUserClicked = async () => {
        if(window.confirm(`Are you sure you want to delete your account?`) === true) {
            await deleteUser({ id: user.id })
            sendLogout()
        } else {
            return
        }
    }


    let canSave
        if (password) {
            canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading
        } else {
            canSave = [roles.length, validUsername].every(Boolean) && !isLoading
        }


    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>
            <h1 className="edit-title">Edit User</h1>
            <div className="edit-center">
            <form className="edit-form" onSubmit={e => e.preventDefault()}>
                <details>
                <summary>Update Password</summary>

                <label htmlFor="password">
                    Password:
                <input
                    className=""
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onPasswordChanged}
                />
                </label>
                </details>
                <label htmlFor="name">
                    Name
                <input
                    className=""
                    id="name"
                    name="name"
                    type="name"
                    value={firstName}
                    onChange={onFirstNameChanged}
                />
                
                </label>
                {!isSVL && <label htmlFor="lastName">
                    Last Name
                <input
                    className=""
                    id="lastName"
                    name="lastName"
                    type="name"
                    value={lastName}
                    onChange={onLastNameChanged}
                />
                
                </label>}
                {(isSVL || isAdmin) && <label htmlFor="address">
                    Address
                <input
                    className=""
                    id="address"
                    name="address"
                    type="text"
                    value={address}
                    onChange={onAddressChanged}
                />
                
                </label>}
                {(isSVL || isAdmin) && <label htmlFor="city">
                    City
                <input
                    className=""
                    id="city"
                    name="city"
                    type="text"
                    value={city}
                    onChange={onCityChanged}
                />
                
                </label>}
                <label>
                State
                    <select 
                        value={region}
                        onChange={onRegionChanged}
                        required>
                        <option value=""></option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                </label>
                {!isSVL && <h2 className="league-title">Sub Info</h2>}
                {!isSVL && <label>Activate Sub
                    <select
                        value={sub}
                        defaultValue={user.sub}
                        onChange={onSubChanged}    
                    >
                        <option value={false}></option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </label>}
                {!isSVL && <p>Be honest. Volleysub reserves the right to update Sub Ratings at any time.</p>}
                {!isSVL && <label>Hitter Rating
                <select
                    value={hitterSkillLevel}
                    onChange={onHitterSkillLevelChanged}
                >
                    <option value=""></option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                </select>
                </label>}

                {!isSVL && <label>Passer Rating
                <select
                    value={passerSkillLevel}
                    onChange={onPasserSkillLevelChanged}
                >
                    <option value=""></option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option></select>
                </label>}

                {!isSVL && <label>Setter Rating
                <select
                    value={setterSkillLevel}
                    onChange={onSetterSkillLevelChanged}
                >
                    <option value=""></option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                </select>
                </label>}

                <div className="edit-sub-button-container">
                        <button
                            className="sub-button"
                            title="Save"
                            onClick={onSaveUserClicked}
                            disabled={!canSave}
                        >
                            Update User
                        </button>
                        <button
                            className="delete-button"
                            title="Delete"
                            onClick={onDeleteUserClicked}
                        >
                            Delete User
                        </button>
                    </div>
            </form>
            </div>
        </>
    )

    return content
}
export default EditUserForm