import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import '../multiForms.css';

const VSMultiContact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [body, setBody] = useState("");

    const onChangedName = e => setName(e.target.value)
    const onChangedEmail = e => setEmail(e.target.value)
    const onChangedBody = e => setBody(e.target.value)

    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        window.Email.send({
            SecureToken : "92946103-4f3b-498c-b10d-80264d922482",
            To : "csheehan@volleysub.com",
            From : email,
            Subject : `Volleysub Logged Out Contact Form - ${name}`,
            Body : body
        }).then(
            navigate('/sent')
        );
    }

    return(
        <div>
        <VSLoginHeader />
            <div className="MultiContactFormContainer">
                <div className="MultiContactFormTitle">Contact Volleysub</div>
                <form className="MultiContactForm" onSubmit={handleSubmit}>
                <label> 
                Name
                    <input 
                        name=""
                        value={name}
                        type="text"
                        onChange={onChangedName}
                        required
                    />
                </label>
                <label>
                Email
                    <input
                        name="email"
                        value={email}                             
                        type="email"
                        onChange={onChangedEmail}
                        required
                    />
                </label>
                <label className="MultiContactTextareaLabel">
                Let us know any questions you have or how we can improve Volleysub:
                    <textarea 
                        className="MultiContactTextarea"
                        value={body}
                        onChange={onChangedBody}
                        required
                        />
                </label>             
                <input className="FormSubmitButton" type="submit"/>
                </form>
            </div>
            <VSLoginFooter />
        </div>
    );
}

export default VSMultiContact;