import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewSessionMutation } from "./sessionApiSlice"
import useAuth from "../../hooks/useAuth"

const NewSessionForm = () => {

    const { username, firstName } = useAuth()

    const [addNewSession, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewSessionMutation()

    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [status, setStatus] = useState(["Registration"])
    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')
    const [svl, setSvl] = useState(username)
    //eslint-disable-next-line
    const [svlName, setSvlName] = useState(firstName)
    

    useEffect(() => {
        if (isSuccess) {
            setName('')
            setStartDateTime('')
            setEndDateTime('')
            setStatus('')
            setSvl('')
            navigate('/dash/sessions')
        }
    }, [isSuccess, navigate])

    const onNameChanged = e => setName(e.target.value)
    const onStartDateTimeChanged = e => setStartDateTime(e.target.value)
    const onEndDateTimeChanged = e => setEndDateTime(e.target.value)

    const canSave = [name, startDateTime, endDateTime].every(Boolean) && !isLoading && (endDateTime > startDateTime)

    const onSaveSessionClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewSession({ svl, svlName, name, status, startDateTime, endDateTime })
        }
    }


    const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveSessionClicked}>
                <div className="form__title-row">
                    <h2 className="league-title">New Session</h2>
                </div>
                <label className="form__label" htmlFor="name">
                    Name:</label>
                <input
                    className=""
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={onNameChanged}
                />

                <label className="form__label" htmlFor="startDate">
                    Start Date/Time:</label>
                <input
                    className=""
                    type="datetime-local"
                    id="startDate"
                    name="startDate"
                    value={startDateTime}
                    onChange={onStartDateTimeChanged}
                />

                <label className="form__label" htmlFor="endDate">
                    End Date:</label>
                <input
                    className=""
                    type="datetime-local"
                    id="endDate"
                    name="endDate"
                    value={endDateTime}
                    onChange={onEndDateTimeChanged}
                />
                    <div>
                        <button
                            className="new-session-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            Create New Session
                        </button>
                    </div>
            </form>
        </>
    )

    return content
}

export default NewSessionForm