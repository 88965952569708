import React from "react";
import { useState } from "react";
import '../multiForms.css';
import { useGetUsersQuery } from "../features/users/usersApiSlice";
import axios from "axios";

const EmailAll = () => {

    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("");

    const onSubjectChanged = e => setSubject(e.target.value)
    const onChangedBody = e => setBody(e.target.value)

    const { users }  = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.entities
        }),
    }) 

    const filteredUsers = Object.values(users)
    const emailArray = filteredUsers.map((email) => email.email)
    console.log(emailArray)
    const emailLoop = (e) => {
        e.preventDefault()
        for (let i = 0; i< emailArray.length; i++) {
                axios.post("https://volleysub-api.onrender.com/emailAll", {
                email: "csheehan@volleysub.com",
                subject: subject,
                body: body
                }
            )
        }
    }

    return(
        <div>
                <div className="MultiContactFormTitle">Email All Users</div>
                <form  onSubmit={emailLoop}>
                <label className="form__label" htmlFor="subject">
                    Email Subject:
                    <input
                    className=""
                    id="subject"
                    name="subject"
                    type="text"
                    autoComplete="off"
                    value={subject}
                    onChange={onSubjectChanged}
                    />
                </label>
                <label className="MultiContactTextareaLabel">
                    Email Body:
                    <textarea 
                        value={body}
                        onChange={onChangedBody}
                        //required
                        />
                </label>             
                <input className="FormSubmitButton" type="submit"/>
                </form>
        </div>
    );
}

export default EmailAll;