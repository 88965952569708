import React from 'react'
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import coverPicture from '../Facebook Cover Image.png'
import '../multiForms.css'


const AboutUs = () => {

    return (
        <div>
        <VSLoginHeader />
            <div className='aboutUsContainer'>
                <h1 className='sentMessageTitle'>About Us</h1>
                <img src={coverPicture} alt=""></img>
                <p className='about-us-lines'>At Volleysub, our mission is to grow the sport of volleyball for all. 
                    Whether it be playing in season-long league, a weekend tournament, or just filling in as a sub for the night, 
                    we want to provide our players with as many quality opportunities to play as possible. 
                    We do this in a couple of ways. 
                    First, we created and maintain our own league management software allowing us to optimize the experience for our players. 
                    Next, we run our own leagues or work with volleyball community leaders to expand into new cities. 
                    Lastly, we have not taken any outside funding to keep the focus on our players and grow our community sustainably.</p>
                <p className='about-us-lines'>The first Volleysub league will be launching in April 2025 in Lexington, KY.</p>
                <p className='about-us-lines'>Want to run your own Volleysub league franchise in your city? <a className='about-link' href='/franchise'>Fill out the form on the franchise page</a> and we will be in touch.</p>
                <p className='about-us-lines'> 
                    Volleysub is always looking for tech, volleyball, and sales talent. If Volleysub sounds like a company you 
                    would like to work for, <a className='about-link' href='/contact'>send us a note on the contact page</a>.
                </p>
            </div>
            
        <VSLoginFooter />
        </div>
    )
}

export default AboutUs