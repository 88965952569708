import { useNavigate } from "react-router-dom"

const PaymentError = () => {

    const id = sessionStorage.leagueId

    const navigate = useNavigate()

    const backToLeagues = () => {
        navigate(`/dash/leagues/${id}`)
    }

    return (
        <>
        <h2 className="league-title">Error</h2>
        <div className="success-details">
        <h4>There was an error processing the payment card</h4>
        <h4>Please confirm card number, expiration date, and CVC and try again</h4>
        </div>
        <button onClick={backToLeagues} className="session-update-button">Return to League</button>
        </>
    )
}

export default PaymentError