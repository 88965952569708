import React from 'react'
import { useNavigate } from 'react-router-dom'
import VSLoginHeader from '../../components/VSLoginHeader'
import VSLoginFooter from '../../components/VSLoginFooter'
import '../../multiForms.css'


const Subscribed = () => {

    const navigate = useNavigate()
    const backToHome = () => {
        navigate('/')
    }

  return (
    <div>
    <VSLoginHeader />
        <div className='sentMessageContainer'>
            <h1 className='sentMessageTitle'>Subscribed!</h1>
            <p className='sent-subtitle'>Please check your inbox every Friday morning for your weekly volleyball newsletter</p>
            <button className='sentButton' onClick={backToHome}>Back to Home</button>
        </div>
    <VSLoginFooter />
    </div>
  )
}

export default Subscribed