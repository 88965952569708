import React from 'react'
import useAuth from '../hooks/useAuth'
import { useEffect } from 'react'
import useTitle from '../hooks/useTitle'
import { useNavigate } from 'react-router-dom'

const PlayerLeagues = () => {

  const { username, isSVL, isAdmin } = useAuth()
  useTitle(`Volleysub: ${username}`)

  const navigate = useNavigate()

  useEffect(() => {
      if(isAdmin) {
          navigate('/dash/profile', { replace: true})
      }
  })
  useEffect(() => {
      if(isSVL) {
          navigate('/dash/profile', { replace: true})
      }
  })

  return (
    <>
    <h1 className='league-title'>Leagues</h1>

    <div className='league-content'>
    <h3 className='league-title'>Open Registration</h3>
      <table className='open-leagues'>
        <tr>
          <th className='league-headers' scope="col">Status</th>
          <th className='league-headers' scope="col">SVL</th>
          <th className='league-headers' scope="col">Date Range</th>
          <th className='league-headers' scope="col">Day</th>
          <th className='league-headers' scope="col">Format</th>
          <th className='league-headers' scope="col">Skill</th>
          <th className='league-headers' scope="col">Sign up</th>
        </tr>
        <tbody>

        </tbody>
      </table>


      <h3 className='league-title'>My Current Leagues</h3>
        <table className='open-leagues'>
        <tr>
          <th className='league-headers' scope="col">Status</th>
          <th className='league-headers' scope="col">SVL</th>
          <th className='league-headers' scope="col">Date Range</th>
          <th className='league-headers' scope="col">Day</th>
          <th className='league-headers' scope="col">Format</th>
          <th className='league-headers' scope="col">Skill</th>
        </tr>
        <tbody>
        </tbody>
      </table>

      <h3 className='league-title'>Our Partners</h3>
        <div className='partners'>
            <p>King Louie's</p>
            <p>J-Town Beach</p>
        </div>
      </div>
    </>
  )
}

export default PlayerLeagues