import { useNavigate } from 'react-router-dom'
import { useGetTeamsQuery } from './TeamApiSlice'
import { memo } from 'react'
import useAuth from '../../hooks/useAuth'

const Team = ({ teamId }) => {

    const { isSVL, isAdmin } = useAuth()
    const navigate = useNavigate()
    const leagueId = sessionStorage.leagueId
    

    const {team} = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            team: data?.entities[teamId],
        }),
    })

    const handleEdit = () => navigate(`../${teamId}`)

    if(team.league === leagueId && team.waitlist === false) {
        return (
            <>
            <tr className='session-row'>
                <td>{team.teamName}</td>
                <td >{team.wins}</td>
                <td>{team.losses}</td>
                { (isSVL || isAdmin) && <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Update W/L
                    </button>
                </td>}
            </tr>
            </>
        )
    }
}

const memoizedTeam = memo(Team)

export default memoizedTeam