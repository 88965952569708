import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../multiForms.css'


const PlayerSent = () => {

    const navigate = useNavigate()
    const backToHome = () => {
        navigate('/dash')
    }

  return (
    <div>
        <div className='sentMessageContainer'>
            <h1 className='sentMessageTitle'>Sent Successfully</h1>
            <p>Thank you for your submission. The Volleysub team will be reaching out to you shortly!</p>
            <p>If you do not receive a response in 1-2 days, please ensure your email address is correct and retry.</p>
            <button className='sentButton' onClick={backToHome}>Back to Home</button>
        </div>
    </div>
  )
}

export default PlayerSent