import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const newsletterAdapter = createEntityAdapter({})

const initialState = newsletterAdapter.getInitialState()

export const newsletterApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
            getNewsletter: builder.query({
                query: () => ({
                    url: '/newsletter',
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError
                    },
                }),
                transformResponse: responseData => {
                    const loadedNewsletter = responseData.map(newsletter => {
                        newsletter.id = newsletter._id
                        return newsletter
                    });
                    return newsletterAdapter.setAll(initialState, loadedNewsletter)
                },
                providesTags: (result, error, arg) => {
                    if (result?.ids) {
                        return [
                            { type: 'Newsletter', id: 'LIST' },
                            ...result.ids.map(id => ({ type: 'Newsletter', id }))
                        ]
                    } else return [{ type: 'Newsletter', id: 'LIST' }]
                }
            }),
            addNewNewsletter: builder.mutation({
            query: initialUserData => ({
                url: '/newsletter',
                method: 'POST',
                body: {
                    ...initialUserData,
                },
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        deleteNewsletter: builder.mutation({
            query: ({ id }) => ({
                url: `/newsletter`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetNewsletterQuery,
    useAddNewNewsletterMutation,
    useDeleteNewsletterMutation,
} = newsletterApiSlice

// returns the query result object
export const selectNewsletterResult = newsletterApiSlice.endpoints.getNewsletter.select()

// creates memoized selector
const selectNewsletterData = createSelector(
    selectNewsletterResult,
    newsletterResult => newsletterResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllNewsletter,
    selectById: selectNewsletterById,
    selectIds: selectNewsletterIds
    // Pass in a selector that returns the users slice of state
} = newsletterAdapter.getSelectors(state => selectNewsletterData(state) ?? initialState)