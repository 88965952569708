import { useGetTeamsQuery } from "./TeamApiSlice"
import Schedule from "./Schedule"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import useTitle from "../../hooks/useTitle"
import '../../SVLSide.css'


const ScheduleList = ({ teamId, teamName }) => {
    useTitle('Volleysub: Teams')

    const { isSVL, isAdmin } = useAuth()
    const navigate = useNavigate()

    const {
        data: team,
        isSuccess,
    } = useGetTeamsQuery('teamsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content


    if (isSuccess) {
        const { ids } = team
        // eslint-disable-next-line
        let filteredIds
        if (isSVL || isAdmin) {
            filteredIds = [...ids]
        } else {
            // eslint-disable-next-line
            filteredIds = ids
        }

        const leagueURL = window.location.pathname.slice(0, -19)
    
        const backToLeague = (e) => {
            e.preventDefault()
            navigate(leagueURL.toString())
        }


        content = (
            <>
            <h2 className="league-title">Schedule</h2>
            <div>
                <p className="league-links" onClick={backToLeague}>Back to League</p>
                <p>**Attention Players** Schedule is subject to change and may not be complete until league is full or season has started.</p>
                <p>Times will fill out as teams sign up</p>
            </div>
            <Schedule key={teamId} teamId={teamId}/>
            </>
        )
    }

        return content

}
export default ScheduleList