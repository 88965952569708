import { useGetNotesQuery } from "./notesApiSlice"
import Note from "./Note"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const NotesList = () => {
    useTitle('Volleysub: Notifications List')

    const { isSVL, isAdmin } = useAuth()

    const {
        data: notes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotesQuery('notesList', {
        pollingInterval: 150000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content
    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids } = notes

        let filteredIds
        if (isSVL || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids
        }

        const tableContent = ids?.length && filteredIds.map(noteId => <Note key={noteId} noteId={noteId} />)

        content = (
            <>
            <h2 className="league-title">Open Sub Requests</h2>
            <table className="session-table-header-group">
                <thead >
                    <tr>
                        <th scope="col" className="session-table-header">Requestor</th>
                        <th scope="col" className="session-table-header">Team</th>
                        <th scope="col" className="session-table-header">Date</th>
                        <th scope="col" className="session-table-header visible">Time</th>
                        <th scope="col" className="session-table-header">Location</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
            </>
        )
    }
    return content
}
export default NotesList