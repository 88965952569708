import { useNavigate } from 'react-router-dom'
import { useGetGamesQuery } from './gameApiSlice'
import { memo } from 'react'
import useAuth from '../../hooks/useAuth'
import '../../playerSide.css'
import '../../SVLSide.css'

const Game = ({ gameId }) => {

    const { game } = useGetGamesQuery("gamesList", {
        selectFromResult: ({ data }) => ({
            game: data?.entities[gameId]
        }),
    })
    
    const leagueId = sessionStorage.leagueId

    const navigate = useNavigate()
    const { isSVL, isAdmin } = useAuth()

    const handleEdit = () => navigate(`../schedule/${gameId}`)

    const matchDateTime = new Date(game.dateTime).toLocaleString('en-US', { timeZone: 'UTC', day: 'numeric', month: 'short', weekday: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit" })

    const t1g1 = game.team1Game1Score
    const t1g2 = game.team1Game2Score
    const t1g3 = game.team1Game3Score
    const t2g1 = game.team2Game1Score
    const t2g2 = game.team2Game2Score
    const t2g3 = game.team2Game3Score


    if ((isSVL || isAdmin) && game.league === leagueId) {

        return (
            <>
            <tr className='session-row'>
                <td>{matchDateTime}</td>
                <td>{game.team1} - {game.team2}</td>
                <td>{t1g1} - {t2g1} | {t1g2} - {t2g2} | {t1g3} - {t2g3}</td>


                {(isSVL || isAdmin) && <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Select Game
                    </button>
                </td>}
            </tr>
            </>
        )

    } else if((!isSVL || !isAdmin) && game.league === leagueId) {
        return (
        <>
            <tr className='session-row'>
                <td>{matchDateTime}</td>
                <td>{game.team1} - {game.team2}</td>
                <td>{t1g1} - {t2g1} | {t1g2} - {t2g2} | {t1g3} - {t2g3}</td>
            </tr>
            </>
    )
    }
        else return null
}

const memoizedGame = memo(Game)

export default memoizedGame