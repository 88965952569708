import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public';
import Login from './features/auth/Login';
import Sent from './components/sent';
import VSContactForm from './components/VSContactForm';
import VSSVLHost from './components/VSSVLHostForm';
import VSPromoteForm from './components/VSPromoteForm';
import DashLayout from './components/DashLayout'
import ForgotPassword from './components/ForgotPassword';
import Welcome from './features/auth/Welcome'
import NotesList from './features/notes/NotesList'
import NotificationsList from './features/notes/NotificationsList'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './components/NewUserForm'
import EditNote from './features/notes/EditNote'
import NewNote from './features/notes/NewNote'
import SpecNote from './features/notes/NewNoteFormSpecific'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle';
import PlayerLeagues from './components/PlayerLeagues';
import Subs from './components/Subs';
import PlayerProfile from './components/PlayerProfile';
import PlayerInfo from './components/playerInfo';
import PlayerContactForm from './components/PlayerContactForm';
import PlayerSent from './components/PlayerSent';
import NewSVLForm from './components/NewSVLForm';
import SVLLandingPage from './components/SVLLandingPage';
import NewSession from './features/sessions/NewSession';
import SessionsList from './features/sessions/SessionsList';
import EditSession from './features/sessions/EditSession';
import NewLeague from './features/leagues/NewLeague';
import EditLeague from './features/leagues/EditLeague';
import LeaguesList from './features/leagues/LeaguesList';
import EditGame from './features/games/EditGame';
import GamesList from './features/games/GamesList';
import CheckoutForm from './features/teams/CheckoutForm';
import Success from './features/teams/PaymentSuccess';
import WaitlistSuccess from './features/teams/WaitlistSuccess';
import WaitlistList from './features/teams/WaitlistList'
import Decline from './features/teams/PaymentError';
import TeamsList from './features/teams/TeamsList';
import NewTeam from './features/teams/NewTeam';
import EditTeam from './features/teams/EditTeam';
import ScheduleList from './features/teams/ScheduleList';
import ForgotPasswordSent from './components/forgotPasswordSent';
import ResetPassword from './components/ResetPassword';
import ForgotPasswordNotFound from './components/forgotPasswordNotFound';
import PasswordUpdated from './components/PasswordUpdated';
import Newsletter from './features/newsletter/newsletter';
import NewNewsletter from './features/newsletter/newNewsletterForm'
import Subscribed from './features/newsletter/subscribed';
import EmailAll from './components/EmailAll';
import AboutUs from './components/AboutUs'
import VSFranchiseForm from './components/VSFranchiseForm';
import LeagueRules from './features/leagues/LeagueRules';



function App() {
  useTitle('Volleysub')

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />
        <Route path="new" element={<NewUserForm />} />
        <Route path="host" element={<VSSVLHost />} />
        <Route path="promote" element={<VSPromoteForm />} />
        <Route path="contact" element={<VSContactForm />} />
        <Route path="sent" element={<Sent />} />
        <Route path="forgotPasswordSent" element={<ForgotPasswordSent />} />
        <Route path="forgotPasswordNotFound" element={<ForgotPasswordNotFound />} />
        <Route path="passwordUpdated" element={<PasswordUpdated />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path='resetPassword/:id/:token' element={<ResetPassword />} />
        <Route path="newsletter" element={<Newsletter />} />
        <Route path="subscribed" element={<Subscribed />} />
        <Route path="aboutUs" element={<AboutUs />} />
        <Route path="franchise" element={<VSFranchiseForm />} />


        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
            <Route element={<Prefetch />}>
              <Route path="dash" element={<DashLayout />}>
              <Route path="playerleagues" element={<PlayerLeagues />} />
              <Route path="subs" element={<Subs />} />
              <Route path="profile" element={<PlayerProfile />} />
              <Route path="playerinfo" element={<PlayerInfo />} />
              <Route path="playercontact" element={<PlayerContactForm />} />
              <Route path="playersent" element={<PlayerSent />} />

              <Route element={<RequireAuth allowedRoles={ROLES.SVL} />} >
                <Route path="SVLHome" element={<SVLLandingPage />}/>
              </Route>

                <Route index element={<Welcome />} />
                
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewSVLForm />} />
                  </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Player, ROLES.Admin]}/>}>
                  <Route path="notes">
                    <Route index element={<NotesList />} />
                    <Route path="notifications" element ={<NotificationsList />} />
                    
                    <Route path=":id" element={<EditNote />} />
                    <Route path="new" element={<NewNote />} />
                    <Route path="newspecific" element={<SpecNote />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.SVL, ROLES.Admin]}/>}>
                  <Route path="sessions">
                    <Route index element={<SessionsList />} />
                    <Route path="new" element={<NewSession />} />
                    <Route path=":id" element={<EditSession />}/>
                    <Route path="leagues" element={<LeaguesList />}/>
                    </Route>
                </Route>


                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                  <Route path="newsletterSetup">
                    <Route index element={<NewNewsletter />} />
                    </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                  <Route path="emailAll">
                    <Route index element={<EmailAll />} />
                    </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.SVL, ROLES.Player, ROLES.Admin]}/>}>
                  <Route path="leagues">
                    <Route index element={<LeaguesList />} />
                    <Route path="new" element={<NewLeague />} />
                    <Route path=":id">
                      <Route index element={<EditLeague />} />
                        <Route path="LeagueRules" element={<LeagueRules />} />
                        <Route path="teams">
                          <Route index element={<TeamsList />} />
                          <Route path="TESTschedule" element={<ScheduleList />} />
                          <Route path="schedule" element={<GamesList />} />
                          <Route path="schedule/:id" element={<EditGame />} />
                          <Route path="new" element={<NewTeam />} />
                          <Route path=":id" element={<EditTeam />}/>
                          <Route path="checkout" element={<CheckoutForm />} />
                          <Route path="success" element ={<Success />} />
                          <Route path="waitlistSuccess" element ={<WaitlistSuccess />} />
                          <Route path="decline" element ={<Decline />} />
                          <Route path="waitlist" element={<WaitlistList />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.SVL, ROLES.Player, ROLES.Admin]}/>}>
                  <Route path="teams">
                    <Route index element={<TeamsList />} />
                    <Route path="new" element={<NewTeam />} />
                    <Route path=":id" element={<EditTeam />}/>
                    </Route>
                </Route>

              </Route>{/* End Dash */}
            </Route>
          </Route>
        </Route>{/* End Protected Routes */}

      </Route>
    </Routes >
  );
}

export default App;