import { useNavigate } from 'react-router-dom'
import { useGetLeaguesQuery, useUpdateLeagueMutation } from './leagueApiSlice'
import { memo } from 'react'
import { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import '../../playerSide.css'
import '../../SVLSide.css'

const League = ({ leagueId }) => {

    const { league } = useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            league: data?.entities[leagueId]
        }),
    })

    const sessionId = sessionStorage.sessionId
    
    const navigate = useNavigate()
    const { isSVL } = useAuth()
    
    const formatSlice = league.format.slice(1)
    const leagueFormat = league.format.charAt(0).toUpperCase() + formatSlice

    const handleEdit = () => navigate(`/dash/leagues/${leagueId}`)

    const [updateLeague] = useUpdateLeagueMutation()

    const updateStage = async () => {
        if (league.stage === "Active" || league.stage === "Inactive") {
            return
        } else if ((league.stage === "Registration" || !league.stage) && new Date().toJSON() > league.startDateTime) {
            await updateLeague({ id:leagueId, active: true, stage: "Active", svl: league.svl, svlName: league.svlName, court: league.court, startDateTime: league.startDateTime, numberOfWeeks: league.numberOfWeeks, timeIncrement: league.timeIncrement, skillLevel: league.skillLevel, format: league.format, day: league.day, genderRequirement: league.genderRequirement, scheduleGenerated:league.scheduleGenerated, maxTeams: league.maxTeams, cost: league.cost, cash: league.cash, tournamentPng: league.tournamentPng, address: league.address, city: league.city, region: league.region })
        }
    }

    useEffect(() => {
        updateStage()
        //eslint-disable-next-line
    },[])

    if (isSVL && league.session === sessionId && league.active === true) {

        return (
            <>
            <tr className='session-row'>
                <td>{league.svlName}</td>
                <td>{league.sessionName}</td>
                <td>{league.stage}</td>
                <td className="visible">{league.day}</td>
                <td className="visible">{league.skillLevel}</td>
                <td className="visible">{leagueFormat}</td>
                <td className="visible">{league.court}</td>

                <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Select League
                    </button>
                </td>
            </tr>
            </>
        )

    } else if(!isSVL && league.active === true) {
        return (
        <>
            <tr className='session-row'>
                <td>{league.svlName}</td>
                <td>{league.sessionName}</td>
                <td>{league.stage}</td>
                <td className='visible'>{league.day}</td>
                <td className='visible'>{league.skillLevel}</td>
                <td className='visible'>{leagueFormat}</td>
                <td className='invisible'>{league.day} - {league.skillLevel} - {leagueFormat}</td>
                <td className='visible'>{league.court}</td>
                <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Select League
                    </button>
                </td>
            </tr>
            </>
    )
    }
        else return null
}

const memoizedLeague = memo(League)

export default memoizedLeague