import { useState } from "react"
import { useGetNewsletterQuery } from "./newsletterApiSlice"
import { useNavigate } from "react-router-dom"
import axios from "axios"

const NewNewsletterForm = () => {

    const navigate = useNavigate()

    //for volleyball world news portion
    const [headline1, setHeadline1] = useState("")
    const [headline1Link, setHeadline1Link] = useState("")
    const [headline1Blurb, setHeadline1Blurb] = useState("")
    const [headline2, setHeadline2] = useState("")
    const [headline2Link, setHeadline2Link] = useState("")
    const [headline2Blurb, setHeadline2Blurb] = useState("")
    const [headline3, setHeadline3] = useState("")
    const [headline3Link, setHeadline3Link] = useState("")
    const [headline3Blurb, setHeadline3Blurb] = useState("")

    //ads portion
    const [ad1, setAd1] = useState("")
    const [ad1Link, setAd1Link] = useState("")
    const [ad1Blurb, setAd1Blurb] = useState("")
    const [ad2, setAd2] = useState("")
    const [ad2Link, setAd2Link] = useState("")
    const [ad2Blurb, setAd2Blurb] = useState("")

    //svl news portion
    const [sVLNews1, setSVLNews1] = useState("")
    const [sVLNews1Link, setSVLNews1Link] = useState("")
    const [sVLNews1Blurb, setSVLNews1Blurb] = useState("")
    const [sVLNews2, setSVLNews2] = useState("")
    const [sVLNews2Link, setSVLNews2Link] = useState("")
    const [sVLNews2Blurb, setSVLNews2Blurb] = useState("")

    //volleysub new portion
    const [vS1, setVS1] = useState("")
    const [vS1Link, setVS1Link] = useState("")
    const [vS1Blurb, setVS1Blurb] = useState("")
    const [vS2, setVS2] = useState("")
    const [vS2Link, setVS2Link] = useState("")
    const [vS2Blurb, setVS2Blurb] = useState("")




    //volleyball news
    const onHeadline1Changed = e => setHeadline1(e.target.value)
    const onHeadline1LinkChanged = e => setHeadline1Link(e.target.value)
    const onHeadline1BlurbChanged = e => setHeadline1Blurb(e.target.value)
    const onHeadline2Changed = e => setHeadline2(e.target.value)
    const onHeadline2LinkChanged = e => setHeadline2Link(e.target.value)
    const onHeadline2BlurbChanged = e => setHeadline2Blurb(e.target.value)
    const onHeadline3Changed = e => setHeadline3(e.target.value)
    const onHeadline3LinkChanged = e => setHeadline3Link(e.target.value)
    const onHeadline3BlurbChanged = e => setHeadline3Blurb(e.target.value)

    //ads
    const onAd1Changed = e => setAd1(e.target.value)
    const onAd1LinkChanged = e => setAd1Link(e.target.value)
    const onAd1BlurbChanged = e => setAd1Blurb(e.target.value)
    const onAd2Changed = e => setAd2(e.target.value)
    const onAd2LinkChanged = e => setAd2Link(e.target.value)
    const onAd2BlurbChanged = e => setAd2Blurb(e.target.value)

    //svl news
    const onSVLNews1Changed = e => setSVLNews1(e.target.value)
    const onSVLNews1LinkChanged = e => setSVLNews1Link(e.target.value)
    const onSVLNews1BlurbChanged = e => setSVLNews1Blurb(e.target.value)
    const onSVLNews2Changed = e => setSVLNews2(e.target.value)
    const onSVLNews2LinkChanged = e => setSVLNews2Link(e.target.value)
    const onSVLNews2BlurbChanged = e => setSVLNews2Blurb(e.target.value)

    //volleysub news
    const onVS1Changed = e => setVS1(e.target.value)
    const onVS1LinkChanged = e => setVS1Link(e.target.value)
    const onVS1BlurbChanged = e => setVS1Blurb(e.target.value)
    const onVS2Changed = e => setVS2(e.target.value)
    const onVS2LinkChanged = e => setVS2Link(e.target.value)
    const onVS2BlurbChanged = e => setVS2Blurb(e.target.value)


            //getting newsletter db
            
                const { newsletter } = useGetNewsletterQuery("newsletterList", {
                    selectFromResult: ({ data }) => ({
                        newsletter: data?.entities
                    }),
                }) 
                
                    //filtering down to an array of emails
                const filteredArray = Object.values(newsletter).filter((email) => email.email).map((email) => email.email)


            const emailArray = filteredArray



    //add headlines, ads, svl and vs news to request
    const sendNewsletter = (e) => {
        e.preventDefault()
            for (let i = 0; i < emailArray.length; i++) {
            axios.post("https://volleysub-api.onrender.com/newsletterTest", {
                email: emailArray[i],
                headline1: headline1,
                headline1Link: headline1Link,
                headline1Blurb: headline1Blurb,
                headline2: headline2,
                headline2Link: headline2Link,
                headline2Blurb: headline2Blurb,
                headline3: headline3,
                headline3Link: headline3Link,
                headline3Blurb: headline3Blurb,
                ad1: ad1,
                ad1Link: ad1Link,
                ad1Blurb: ad1Blurb,
                ad2: ad2,
                ad2Link: ad2Link,
                ad2Blurb: ad2Blurb,
                sVLNews1: sVLNews1,
                sVLNews1Link: sVLNews1Link,
                sVLNews1Blurb: sVLNews1Blurb,
                sVLNews2: sVLNews2,
                sVLNews2Link: sVLNews2Link,
                sVLNews2Blurb: sVLNews2Blurb,
                vS1: vS1,
                vS1Link: vS1Link,
                vS1Blurb: vS1Blurb,
                vS2: vS2,
                vS2Link: vS2Link,
                vS2Blurb: vS2Blurb
            })
        }
        navigate('/dash/profile')
    }

    //preview newsletter
    const previewNewsletter = (e) => {
        e.preventDefault()
            axios.post("http://localhost:3500/newsletterPreview", {
                email: "csheehan@volleysub.com",
                headline1: headline1,
                headline1Link: headline1Link,
                headline1Blurb: headline1Blurb,
                headline2: headline2,
                headline2Link: headline2Link,
                headline2Blurb: headline2Blurb,
                headline3: headline3,
                headline3Link: headline3Link,
                headline3Blurb: headline3Blurb,
                ad1: ad1,
                ad1Link: ad1Link,
                ad1Blurb: ad1Blurb,
                ad2: ad2,
                ad2Link: ad2Link,
                ad2Blurb: ad2Blurb,
                sVLNews1: sVLNews1,
                sVLNews1Link: sVLNews1Link,
                sVLNews1Blurb: sVLNews1Blurb,
                sVLNews2: sVLNews2,
                sVLNews2Link: sVLNews2Link,
                sVLNews2Blurb: sVLNews2Blurb,
                vS1: vS1,
                vS1Link: vS1Link,
                vS1Blurb: vS1Blurb,
                vS2: vS2,
                vS2Link: vS2Link,
                vS2Blurb: vS2Blurb
            })
        }


    const content = (
        <>
            <h2 className="league-title">Newsletter</h2> 
            <div className="sub-links">
                <form className="form" onSubmit={e => e.preventDefault()}>

            
                {/* volleyball news */}
                <h3><b>Volleyball News</b></h3>
                <label className="form__label" htmlFor="headline1">
                    Headline 1:</label>
                <input
                    id="headline1"
                    name="headline1"
                    className=""
                    type="text"
                    value={headline1}
                    onChange={onHeadline1Changed}
                />
                <label className="form__label" htmlFor="headline1Link">
                    Headline 1 Link:</label>
                <input
                    id="headline1Link"
                    name="headline1Link"
                    className=""
                    type="text"
                    value={headline1Link}
                    onChange={onHeadline1LinkChanged}
                />
                <label>
                Headline 1 Blurb:
                </label> 
                    <textarea 
                        className=""
                        value={headline1Blurb}
                        onChange={onHeadline1BlurbChanged}
                        required
                        />
                
                <hr/>
                <label className="form__label" htmlFor="headline2">
                    Headline 2:</label>
                <input
                    id="headline2"
                    name="headline2"
                    className=""
                    type="text"
                    value={headline2}
                    onChange={onHeadline2Changed}
                />
                <label className="form__label" htmlFor="headline2Link">
                    Headline 2 Link:</label>
                <input
                    id="headline2Link"
                    name="headline2Link"
                    className=""
                    type="text"
                    value={headline2Link}
                    onChange={onHeadline2LinkChanged}
                />
                <label>
                Headline 2 Blurb:</label> 
                    <textarea 
                        className=""
                        value={headline2Blurb}
                        onChange={onHeadline2BlurbChanged}
                        required
                        />
                
                <hr/>
                <label className="form__label" htmlFor="headline3">
                    Headline 3:</label>
                <input
                    id="headline3"
                    name="headline3"
                    className=""
                    type="text"
                    value={headline3}
                    onChange={onHeadline3Changed}
                />
                <label className="form__label" htmlFor="headline3Link">
                    Headline 3 Link:</label>
                <input
                    id="headline3Link"
                    name="headline3Link"
                    className=""
                    type="text"
                    value={headline3Link}
                    onChange={onHeadline3LinkChanged}
                />
                <label>
                Headline 3 Blurb:
                </label> 
                    <textarea 
                        className=""
                        value={headline3Blurb}
                        onChange={onHeadline3BlurbChanged}
                        required
                        />
                <hr/>

                {/* newsletter ads */}
                <h3><b>Ads</b></h3>
                <label className="form__label" htmlFor="ad1">
                    Ad 1:</label>
                <input
                    id="ad1"
                    name="ad1"
                    className=""
                    type="text"
                    value={ad1}
                    onChange={onAd1Changed}
                />
                <label className="form__label" htmlFor="ad1Link">
                    Ad 1 Link:</label>
                <input
                    id="ad1Link"
                    name="ad1Link"
                    className=""
                    type="text"
                    value={ad1Link}
                    onChange={onAd1LinkChanged}
                />
                <label>
                Ad 1 Blurb:
                </label> 
                    <textarea 
                        className=""
                        value={ad1Blurb}
                        onChange={onAd1BlurbChanged}
                        required
                        />
                <hr/>
                
                <label className="form__label" htmlFor="ad2">
                    Ad 2:</label>
                <input
                    id="ad2"
                    name="ad2"
                    className=""
                    type="text"
                    value={ad2}
                    onChange={onAd2Changed}
                />
                <label className="form__label" htmlFor="ad2Link">
                    Ad 2 Link:</label>
                <input
                    id="ad2Link"
                    name="ad2Link"
                    className=""
                    type="text"
                    value={ad2Link}
                    onChange={onAd2LinkChanged}
                />
                <label>
                Ad 2 Blurb:
                </label> 
                    <textarea 
                        className=""
                        value={ad2Blurb}
                        onChange={onAd2BlurbChanged}
                        required
                        />
                <hr/>

                {/* svl news */}
                <h3><b>SVL News</b></h3>
                <label className="form__label" htmlFor="sVLNews1">
                    SVL News 1:</label>
                <input
                    id="sVLNews1"
                    name="sVLNews1"
                    className=""
                    type="text"
                    value={sVLNews1}
                    onChange={onSVLNews1Changed}
                />
                <label className="form__label" htmlFor="sVLNews1Link">
                    SVL News 1 Link:</label>
                <input
                    id="sVLNews1Link"
                    name="sVLNews1Link"
                    className=""
                    type="text"
                    value={sVLNews1Link}
                    onChange={onSVLNews1LinkChanged}
                />
                <label>
                SVL News 1 Blurb:
                </label>
                    <textarea 
                        className=""
                        value={sVLNews1Blurb}
                        onChange={onSVLNews1BlurbChanged}
                        required
                        />
                <hr/>
                <label className="form__label" htmlFor="sVLNews2">
                    SVL News 2:</label>
                <input
                    id="sVLNews2"
                    name="sVLNews2"
                    className=""
                    type="text"
                    value={sVLNews2}
                    onChange={onSVLNews2Changed}
                />
                <label className="form__label" htmlFor="sVLNews2Link">
                    SVL News 2 Link:</label>
                <input
                    id="sVLNews2Link"
                    name="sVLNews2Link"
                    className=""
                    type="text"
                    value={sVLNews2Link}
                    onChange={onSVLNews2LinkChanged}
                />
                <label>
                SVL News 2 Blurb:
                </label> 
                    <textarea 
                        className=""
                        value={sVLNews2Blurb}
                        onChange={onSVLNews2BlurbChanged}
                        required
                        />
                <hr/>

                {/* volleysub news */}
                <h3><b>Volleysub News</b></h3>
                <label className="form__label" htmlFor="vS1">
                    Volleysub News 1:</label>
                <input
                    id="vS1"
                    name="vS1"
                    className=""
                    type="text"
                    value={vS1}
                    onChange={onVS1Changed}
                />
                <label className="form__label" htmlFor="vS1Link">
                    Volleysub News 1 Link:</label>
                <input
                    id="vS1Link"
                    name="vS1Link"
                    className=""
                    type="text"
                    value={vS1Link}
                    onChange={onVS1LinkChanged}
                />
                <label>
                Volleysub News 1 Blurb:
                </label>
                    <textarea 
                        className=""
                        value={vS1Blurb}
                        onChange={onVS1BlurbChanged}
                        required
                        />
                <hr/>
                <label className="form__label" htmlFor="vS2">
                    Volleysub News 2:</label>
                <input
                    id="vS2"
                    name="vS2"
                    className=""
                    type="text"
                    value={vS2}
                    onChange={onVS2Changed}
                />
                <label className="form__label" htmlFor="vS2Link">
                    Volleysub News 2 Link:</label>
                <input
                    id="vS2Link"
                    name="vS2Link"
                    className=""
                    type="text"
                    value={vS2Link}
                    onChange={onVS2LinkChanged}
                />
                <label>
                Volleysub News 2 Blurb:
                </label>
                    <textarea
                        className=""
                        value={vS2Blurb}
                        onChange={onVS2BlurbChanged}
                        required
                        />
                

                <div>
                <button
                            title="Preview"
                            className="session-update-button"
                            onClick={previewNewsletter}
                        >
                            Preview
                    </button>
                    <button
                            title="Save"
                            className="session-update-button"
                            onClick={sendNewsletter}
                        >
                            Send Newsletter
                        </button>
                    </div>
                </form>
            </div>
        </>
    )

    return content
}

export default NewNewsletterForm