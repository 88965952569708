import { useNavigate } from "react-router-dom"

const PaymentSuccess = () => {

    const teamName = sessionStorage.teamName
    const leagueName = sessionStorage.leagueName
    const id = sessionStorage.leagueId
    const amount = sessionStorage.amount

    const navigate = useNavigate()

    const backToLeagues = () => {
        navigate(`/dash/leagues/${id}/teams`)
    }

    return (
        <>
        <h2 className="league-title">Signup Successful!</h2>
        <div className="success-details">
        <h4>Thank you for signing up {teamName} in {leagueName} </h4>
        <h5>Please follow link below to submit league fee payment of ${amount}.</h5>
        <h5>Please include team name in payment description.</h5>
        <div className="spacer">
        <h5 className="important">**Payment must be submitted at least one week before league start date or your spot will be given to a waitlisted team.</h5>
        </div>
        </div>
        <button className="session-update-button"><a className="venmo-link" target="_blank" rel="noreferrer" href={`https://account.venmo.com/pay?recipients=volleysub&amount=${amount}&note=${teamName}%20${leagueName}`}>Venmo Volleysub</a></button>
        <button onClick={backToLeagues} className="session-update-button">Return to Standings</button>
        </>
    )
}

export default PaymentSuccess