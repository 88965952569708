import React from 'react'
import { useNavigate } from 'react-router-dom'
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import '../multiForms.css'


const ForgotPasswordSent = () => {

    const navigate = useNavigate()
    const backToHome = () => {
        navigate('/')
    }

  return (
    <div>
    <VSLoginHeader />
        <div className='sentMessageContainer'>
            <h1 className='sentMessageTitle'>Submission Accepted</h1>
            <p className='svl-links'>A password reset link has been sent to your email.</p>
            <p className='sent-subtitle'>If you do not receive an email, please check your spam folder, try again, or reach out to Volleysub.</p>
            <button className='sentButton' onClick={backToHome}>Back to Home</button>
        </div>
    <VSLoginFooter />
    </div>
  )
}

export default ForgotPasswordSent