import { useGetTeamsQuery } from './TeamApiSlice'
import { memo } from 'react'
import moment from 'moment'

const Schedule = ({ teamId }) => {

    const leagueId = sessionStorage.leagueId

    const { teams }  = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            teams: data?.entities
        }),
    }) 

    const schedule = () => {

        const filteredTeams = Object.values(teams).filter((league) => league.league === leagueId && league.waitlist === false)
        const sortedArray = filteredTeams.sort((a,b) => (a.id > b.id) ? 1 : -1)
        const teamArray = sortedArray.map((teamName) => teamName.teamName)


        console.log(teamArray)
        if (teamArray.length < 1) {
            return <p>No Schedule yet, try back later!</p>
        } else {
        
        const timeIncrement = sortedArray[0].timeIncrement
        const gameArray = JSON.parse(JSON.stringify(teamArray))
        
        const rounds = []
        let matchDay = new Date(sortedArray[0].startDateTime)
        let matchTime = new Date(sortedArray[0].startDateTime)
        const endDate = new Date(sortedArray[0].endDateTime)
        const timeDifference = Math.abs(endDate - matchDay)
        const weeks = Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 7))
        const numberOfRounds = weeks

        

        for (let i = 0; i < numberOfRounds; i++) {
            const roundTitle = <p className='matchday'>Week {i + 1} - {matchDay.toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' })}</p>
            const matches = []
            matchTime = new Date(sortedArray[0].startDateTime)

            for (let j = 0; j < gameArray.length / 2; j++) {
                
                const match = () => {
                    if (gameArray[j] === gameArray[gameArray.length - 1 - j]) {
                        return (
                            <div>
                                {matchTime.toLocaleString('en-us', {hour: 'numeric', minute: 'numeric'})} - BYE
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {matchTime.toLocaleString('en-us', {hour: 'numeric', minute: 'numeric'})} - {gameArray[j]} vs. {gameArray[gameArray.length - 1 - j]}
                            </div>
                        )
                    }
                }
                matches.push(match())
                matchTime = moment(matchTime).add(timeIncrement, 'm').toDate()
            }
            rounds.push(
                <div key={i}>
                    {roundTitle}
                    {matches}
                </div>
            )
            gameArray.splice(gameArray.length/2, 0, gameArray[gameArray.length - 1])
            gameArray.pop()
            gameArray.splice(0, 0, gameArray[gameArray.length - 1])
            gameArray.pop()
            matchDay.setDate(matchDay.getDate() + 7)
        }
        return rounds
    }
    }

        return (
            <>
                {schedule()}
            </>
        )


}

const memoizedSchedule = memo(Schedule)

export default memoizedSchedule
