import { useNavigate } from 'react-router-dom'
import { useGetNotesQuery, useUpdateNoteMutation } from './notesApiSlice'
import { memo, useEffect } from 'react'

const Note = ({ noteId }) => {

    const { note } = useGetNotesQuery("notesList", {
        selectFromResult: ({ data }) => ({
            note: data?.entities[noteId]
        }),
    })

    const navigate = useNavigate()

    const [updateNote] = useUpdateNoteMutation('notesList', {
        pollingInterval: 150000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const updateCompletedTag = async () => {
        if(note.completed === true) {
            return
        } else if (new Date().toJSON() > note.date) {
            await updateNote({ id: note.id, completed: true, requestor: note.requestor, recipient: note.recipient, status: note.status, svl: note.svl, team: note.team, date: note.date, court: note.court, skillLevel: note.skillLevel, format: note.format, text: note.text })
        }
    }

    useEffect(() => {
        updateCompletedTag()
        // eslint-disable-next-line
    }, [])

    if (note.recipient === "Anyone" && note.completed === false) {
        const matchDate = new Date(note.date).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' })
        const matchTime = new Date(note.date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })

        const handleEdit = () => navigate(`/dash/notes/${noteId}`)

        return (
            <tr className='session-row'>
                <td>{note.requestor}</td>
                <td>{note.team}</td>
                <td>{matchDate}</td>
                <td className='visible'>{matchTime}</td>
                <td>{note.svl}</td>
                <td>
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        View
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedNote = memo(Note)

export default memoizedNote