import React from 'react'
import useAuth from '../hooks/useAuth'
import { Link } from 'react-router-dom'
import useTitle from '../hooks/useTitle'

const PlayerProfile = () => {
  const { username, isSVL, isAdmin } = useAuth()

  useTitle(`Volleysub: ${username}`)

  return (
    <>
    <h2 className='league-title'>{username}</h2>
    <div className='profile-page'>
      <div className='profile-links-container'>
      <h3 className='league-title'>My Links</h3>
        <div>
          {(!isSVL && !isAdmin) && <p className="svl-links"><Link to="/dash/notes/notifications">My Notifications</Link></p>}
          {isAdmin && <p className="svl-links"><Link to="/dash/newsletterSetup">New Newsletter</Link></p>}
          {(!isSVL) && <p className="svl-links"><Link to="/dash/notes/new">Create New Sub Request</Link></p>}
          {(isAdmin) && <p className="svl-links"><Link to="/dash/notes">All Open Sub Requests</Link></p>}
          {(isSVL || isAdmin) && <p className="svl-links"><Link to="/dash/sessions/new">Add New Session</Link></p>}
          {(isAdmin) && <p className="svl-links"><Link to="/dash/sessions">All Sessions</Link></p>}
          {(isSVL) && <p className="svl-links"><Link to="/dash/sessions">My Sessions</Link></p>}
          {(isAdmin) && <p className="svl-links"><Link to="/dash/leagues">All Leagues</Link></p>}
          {(isAdmin) && <p className="svl-links"><Link to="/dash/users">All Users</Link></p>}
          {isAdmin && <p className="svl-links"><Link to="/dash/emailAll">Email All Users</Link></p>}
          <p className="svl-links"><Link to="/dash/playerinfo">My Info</Link></p>
          {(!isAdmin) && <p className="svl-links"><Link to="/dash/playercontact">Contact Volleysub</Link></p>}
        </div>
      </div>
    </div>
    </>
  )
}

export default PlayerProfile