import { useParams } from 'react-router-dom'
import EditGameForm from './EditGameForm'
import { useGetGamesQuery } from './gameApiSlice'
import { useGetLeaguesQuery } from '../leagues/leagueApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditGame = () => {
    useTitle('Volleysub: Edit Game')

    const { id } = useParams()

    const { game } = useGetGamesQuery("gamesList", {
        selectFromResult: ({ data }) => ({
            game: data?.entities[id]
        }),
    })

    const { leagues } = useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            leagues: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!game || !leagues?.length) return <PulseLoader color={"#FFF"} />


    const content = <EditGameForm game={game} leagues={leagues} />

    return content
}
export default EditGame