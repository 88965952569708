import { useGetTeamsQuery } from "./TeamApiSlice"
import Waitlist from "./Waitlist"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import useTitle from "../../hooks/useTitle"
import '../../SVLSide.css'


const WaitlistList = ({ teamId, teamName }) => {
    useTitle('Volleysub: Waitlist')

    const { isSVL, isAdmin } = useAuth()
    const navigate = useNavigate()

    let content
    const leagueName = sessionStorage.leagueName

    const {
        data: team,
        isSuccess,
    } = useGetTeamsQuery('teamsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    if (isSuccess) {
        const { ids } = team
        let filteredIds
        if (isSVL || isAdmin) {
            filteredIds = [...ids]
        } else {
            //eslint-disable-next-line
            filteredIds = ids
        }

        const leagueURL = window.location.pathname.slice(0, -15)
    
        const backToLeague = (e) => {
            e.preventDefault()
            navigate(leagueURL.toString())
        }



        content = (
            <>
            <h2><span className="league-title">Waitlist -</span> {leagueName}</h2>
            <div>
                <p className="league-links" onClick={backToLeague}>Back to League</p>

            </div>
            <Waitlist key={teamId} teamId={teamId}/>
            </>
        )
    }

        return content

}
export default WaitlistList