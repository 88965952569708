import React, { useState } from "react";
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import '../multiForms.css';
import { useNavigate } from "react-router-dom";

const VSMultiPromote = () => {

    const navigate = useNavigate()

    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [why, setWhy] = useState("");

    const onChangedCompanyName = (e) => setCompanyName(e.target.value)
    const onEmailChanged = (e) => setEmail(e.target.value)
    const onPhoneChanged = (e) => setPhone(e.target.value)
    const onWhyChanged = (e) => setWhy(e.target.value)

    const body = {
        phone: phone,
        message: why
    }

        
        const handleSubmit = (e) => {
        e.preventDefault();
        window.Email.send({
            SecureToken : "92946103-4f3b-498c-b10d-80264d922482",
            To : "csheehan@volleysub.com",
            From : email,
            Subject : `Volleysub Promote Form - ${companyName}`,
            Body : body
        }).then(
            navigate('/sent')
        );
    }

    return(
        <div>
        <VSLoginHeader />
            <div className="MultiPromoteFormContainer">
                <div className="MultiPromoteFormTitle">Promote with Volleysub</div>
                <div className="MultiPromoteFormSubtitle">Partner with Volleysub to promote your company</div>
                <form className="MultiPromoteForm" onSubmit={handleSubmit}>
                <label> 
                Company Name
                    <input 
                        name="companyName"
                        value={companyName}
                        type="text"
                        onChange={onChangedCompanyName}
                        required
                    />
                </label>
                <label>
                Email
                    <input
                        name="email"
                        value={email}                             
                        type="email"
                        onChange={onEmailChanged}
                        required
                    />
                </label>
                <label>
                Phone Number
                    <input 
                        name="phone"
                        value={phone}
                        type="tel"
                        onChange={onPhoneChanged}
                        required
                    />
                </label>
                <div className="MultiPromoteTextareaContainer">
                <label className="MultiPromoteTextareaLabel">
                Tell us about you and why you want to partner with Volleysub:
                    <textarea 
                        className="MultiPromoteTextarea"
                        value={why}
                        onChange={onWhyChanged}
                        required
                        />
                </label>
                </div>
                <input className="FormSubmitButton" type="submit"/>
                </form>
            </div>
            <VSLoginFooter />
        </div>
    );
}

export default VSMultiPromote;
