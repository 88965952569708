import React from 'react'
import { useNavigate } from 'react-router-dom'
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import '../multiForms.css'


const Sent = () => {

    const navigate = useNavigate()
    const backToHome = () => {
        navigate('/')
    }

  return (
    <div>
    <VSLoginHeader />
        <div className='sentMessageContainer'>
            <h1 className='sentMessageTitle'>Sent Successfully</h1>
            <p className='svl-links'>Thank you for your submission. The Volleysub team will be reaching out to you shortly!</p>
            <p className='sent-subtitle'>If you do not receive a response in 1-2 days, please ensure your email address is correct and retry.</p>
            <button className='sentButton' onClick={backToHome}>Back to Home</button>
        </div>
    <VSLoginFooter />
    </div>
  )
}

export default Sent