import { useParams } from 'react-router-dom'
import EditSessionForm from './EditSessionForm'
import { useGetSessionsQuery } from './sessionApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditSession = () => {
    useTitle('Volleysub: Edit Session')

    const { id } = useParams()

    const { username, isSVL, isAdmin } = useAuth()

    const { session } = useGetSessionsQuery("sessionsList", {
        selectFromResult: ({ data }) => ({
            session: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!session || !users?.length) return <PulseLoader color={"#FFF"} />


    if (!isSVL && !isAdmin) {
        if (session.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditSessionForm session={session} users={users} />

    return content
}
export default EditSession