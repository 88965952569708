import { useState, useEffect } from "react"
import { useUpdateSessionMutation, useDeleteSessionMutation } from "./sessionApiSlice"
import { useNavigate, Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const EditSessionForm = ({ session }) => {

    const { isSVL, isAdmin } = useAuth()

    const [updateSession, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateSessionMutation()

    const [deleteSession, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteSessionMutation()

    const navigate = useNavigate()
    
    const [sessionId, setSessionId] = useState(session.id)
    const [name, setName] = useState(session.name)
    const [status, setStatus] = useState(session.status)
    const [startDateTime, setStartDateTime] = useState(session.startDateTime)
    const [endDateTime, setEndDateTime] = useState(session.endDateTime)
    const [svl, setSvl] = useState(session.svl)
    //eslint-disable-next-line
    const [svlName, setSvlName] = useState(session.svlName)

    sessionStorage.setItem("sessionId", session.id)
    sessionStorage.setItem("svl", session.svl)
    sessionStorage.setItem("sessionName", session.name)
    sessionStorage.setItem("svlName", session.svlName)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setName('')
            setStatus('')
            setStartDateTime()
            setEndDateTime()
            setSessionId("")
            setSvl("")
            navigate('/dash')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onNameChanged = e => setName(e.target.value)
    const onStartDateTimeChanged = e => setStartDateTime(e.target.value)
    const onEndDateTimeChanged = e => setEndDateTime(e.target.value)

    const canSave = [name, startDateTime, endDateTime, svl].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        if (canSave) {
            await updateSession({ id: sessionId, svl, svlName, name, status, startDateTime, endDateTime })
        }
    }

    const onDeleteSessionClicked = async () => {
        if(window.confirm(`Are you sure you want to delete Session: ${name}?`) === true) {
            await deleteSession({ id: session.id })
        } else {
            return
        }
    }

    

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isSVL || isAdmin) {
        deleteButton = (
            <button
                title="Delete"
                className="session-delete-button"
                onClick={onDeleteSessionClicked}
            >
                Delete Session
            </button>
        )
    }

    const content = (
        <>
        <h2><span className="edit-session-subtitle">Session - </span>{session.name}</h2>
        
            <h4 className="edit-session-title">Leagues</h4>
            <div >
            <p className="svl-links"><Link to="/dash/leagues">View Leagues</Link></p>
            <p className="svl-links"><Link to="/dash/leagues/new">Add League</Link></p>
            </div>

        <details>
        <summary className="edit-session-title">Edit Session - {session.name}</summary>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    

                </div>
                <label className="form__label" htmlFor="session-name">
                    Name:</label>
                <input
                    className=""
                    id="session-name"
                    name="name"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={onNameChanged}
                />

                <label className="form__label" htmlFor="note-text">
                    Start Date/Time:</label>
                <input
                    className=""
                    id="startDate"
                    name="startDate"
                    type="datetime-local"
                    value={startDateTime}
                    onChange={onStartDateTimeChanged}
                />
                <label className="form__label" htmlFor="note-text">
                    End Date/Time:</label>
                <input
                    className=""
                    id="endDate"
                    name="endDate"
                    type="datetime-local"
                    value={endDateTime}
                    onChange={onEndDateTimeChanged}
                />
                    <div >
                        <button
                            title="Save"
                            className="session-update-button"
                            onClick={onSaveNoteClicked}
                            disabled={!canSave}
                        >
                            Update Session
                        </button>
                        {deleteButton}
                    </div>
            </form>
            </details>
        </>
    )

    return content
}

export default EditSessionForm