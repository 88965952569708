import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetTeamsQuery, useAddNewTeamMutation } from "./TeamApiSlice"
import useAuth from '../../hooks/useAuth'


const NewTeamForm = () => {

    const navigate = useNavigate()
    const [addNewTeam] = useAddNewTeamMutation()
    const { username } = useAuth()

    const { teams }  = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            teams: data?.entities
        }),
    }) 

    const [teamName, setTeamName] = useState('')

    const maxTeams = sessionStorage.maxTeams
    const leagueId = sessionStorage.leagueId
    const wins = 0;
    const losses = 0;
    let paid = false
    

    const filteredTeams = Object.values(teams).filter((league) => league.league === leagueId)
    const maxTeamsCheck = filteredTeams.length


    const onTeamNameChanged = e => setTeamName(e.target.value)
    sessionStorage.setItem("teamName", teamName)

    const newTeam = async () => {
        if(teamName !== "") {
        navigate(`../success`)
        await addNewTeam({ league: leagueId, user: username, teamName, wins, losses, paid, waitlist: false })
        } else {
        alert("team name required")
        }
    }

    const joinWaitlist = async () => {
        if(teamName !== "") {
        navigate(`../waitlistSuccess`)
        await addNewTeam({ league: leagueId, user: username, teamName, wins, losses, paid, waitlist: true })
        } else {
        alert("team name required")
        }
    }

    const backToLeagues = () => {
        navigate(`/dash/leagues`)
    }

    const canSave = [teamName].every(Boolean)

    const content = (
        <>

            <form className="form">
                <div className="form__title-row">
                    <h2 className="league-title">New Team</h2>
                </div>
                <label className="form__label" htmlFor="teamName">
                    Team Name:</label>
                <input
                    className=""
                    id="teamName"
                    name="teamName"
                    type="text"
                    autoComplete="off"
                    value={teamName}
                    onChange={onTeamNameChanged}
                />
                    <div>
                        <button
                            className="new-session-button"
                            title="Save"
                            onClick={newTeam}
                            disabled={!canSave}
                        >
                            Signup
                        </button>
                    </div>
            </form>
        </>
    )
    
    if(maxTeamsCheck >= maxTeams) {
        return(
        <>
        <div className="league-links">
        <p onClick={backToLeagues} >Back to Leagues</p>
        </div>
        <h2 className="league-title">League Full</h2>
        <h4>Max teams for this league has been reached. Enter your team name to join our waitlist and we will let you know if an opening becomes available.</h4>
        <form className="form">
                <div className="form__title-row">
                    
                </div>
                <label className="form__label" htmlFor="teamName">
                    Team Name:</label>
                <input
                    className=""
                    id="teamName"
                    name="teamName"
                    type="text"
                    autoComplete="off"
                    value={teamName}
                    onChange={onTeamNameChanged}
                />
                    <div>
                        <button
                            className="new-session-button"
                            title="Save"
                            onClick={joinWaitlist}
                            disabled={!canSave}
                        >
                            Join Waitlist
                        </button>
                    </div>
            </form>
        </>
    )} else {
    return content
    }
}

export default NewTeamForm