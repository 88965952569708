import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewNoteMutation } from "./notesApiSlice"
import useAuth from "../../hooks/useAuth"

const NewNoteForm = () => {

    const [addNewNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewNoteMutation()

    const navigate = useNavigate()

    const { username, email } = useAuth()

    // eslint-disable-next-line
    const [status, setStatus] = useState('')
    const [team, setTeam] = useState('')
    // eslint-disable-next-line
    const [date, setDate] = useState('')
    // eslint-disable-next-line
    const [time, setTime] = useState('')
    const [svl, setSvl] = useState('')
    const [court, setCourt] = useState('')
    const [skillLevel, setSkillLevel] = useState('')
    const [format, setFormat] = useState('')
    const [text, setText] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setStatus('')
            setDate('')
            setTime('')
            setText('')
            navigate('/dash/notes')
        }
    }, [isSuccess, navigate])

    const onTeamChanged = e => setTeam(e.target.value)
    const onDateChanged = e => setDate(e.target.value)
    const onSvlChanged = e => setSvl(e.target.value)
    const onCourtChanged = e => setCourt(e.target.value)
    const onSkillLevelChanged = e => setSkillLevel(e.target.value)
    const onFormatChanged = e => setFormat(e.target.value)
    const onTextChanged = e => setText(e.target.value)

    const canSave = [team, date, svl, skillLevel, format].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewNote({ requestor: username, requestorEmail: email, recipient: "Anyone", recipientEmail: "", status: "Open", team, date, svl, court, skillLevel, format, text, completed: false })
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveNoteClicked}>
                <div className="form__title-row">
                    <h2 className="league-title">New Sub Request</h2>
                </div>
                <p>Creates a open sub request for any player to accept</p>
                <label className="form__label" htmlFor="team">
                    Team:</label>
                <input
                    id="team"
                    name="team"
                    type="text"
                    autoComplete="off"
                    value={team}
                    onChange={onTeamChanged}
                />
                <label className="form__label" htmlFor="date">
                    Date/Time of Match:</label>
                <input
                    id="date"
                    name="date"
                    type="datetime-local"
                    autoComplete="off"
                    value={date}
                    onChange={onDateChanged}
                />

                <label className="form__label" htmlFor="svl">
                    Location:</label>
                <input
                    id="svl"
                    name="svl"
                    type="text"
                    autoComplete="off"
                    value={svl}
                    onChange={onSvlChanged}
                />
                <label className="form__label" htmlFor="court">
                    Court:</label>
                <input
                    id="court"
                    name="court"
                    type="text"
                    autoComplete="off"
                    value={court}
                    onChange={onCourtChanged}
                />
                <label className="form__label">
                    Skill Level:</label>
                <select
                    value={skillLevel}
                    onChange={onSkillLevelChanged}
                >
                    <option value=""></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C+">C+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                </select>
                <label className="form__label">
                    Format:</label>
                <select
                    value={format}
                    onChange={onFormatChanged}
                >
                    <option value=""></option>
                    <option value="doubles">Doubles</option>
                    <option value="quads">Quads</option>
                    <option value="5s">5s</option>
                    <option value="6s">6s</option>
                    <option value="reverseQuads">Reverse Quads</option>
                </select>

                <label className="form__label" htmlFor="text">
                    Text:</label>
                <textarea
                    id="text"
                    name="text"
                    placeholder="Anything else a sub may need to know?"
                    value={text}
                    onChange={onTextChanged}
                />
                    <button
                        title="Save"
                        disabled={!canSave}
                        className="FormSubmitButton"
                    >
                        Create Sub Request                        
                    </button>
            </form>
        </>
    )

    return content
}

export default NewNoteForm