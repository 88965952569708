import VSMultiHeader from './VSLoginHeader'
import VSMultiFooter from './VSLoginFooter'
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"

const ResetPassword = () => {

    const navigate = useNavigate()
    const PWD_REGEX = /^[A-z0-9!@#$%]{4,100}$/

    const [password, setPassword] = useState()
    // eslint-disable-next-line
    const [validPassword, setValidPassword] = useState(false)
    const {id, token} = useParams()

    const onPasswordChanged = (e) => setPassword(e.target.value)

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
        // eslint-disable-next-line
    }, [password])

    axios.defaults.withCredentials = true
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(`https://volleysub-api.onrender.com/resetPassword/${id}/${token}`, {password})
        .then(res => {
            if(res.data.status === "Success") {
                navigate('/passwordUpdated')
            }
        }).catch(err => console.log(err))
    }




    return (
        <>
            <VSMultiHeader />
                <div className='forgotPasswordContainer'>
                <h1 className='PlayerSignUpTitle'>Reset Password</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Enter New Password
                    <input
                        className="form__input"
                        type="password"
                        id="password"
                        value={password}
                        onChange={onPasswordChanged}
                        autoComplete="off"
                        required
                    />
                    </label>
                    <button className="ForgotSubmitButton">Sumbit</button>
                </form>
            </div>
            <VSMultiFooter />
        </>
    )
}

export default ResetPassword