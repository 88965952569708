import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const sessionsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = sessionsAdapter.getInitialState()

export const sessionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.query({
            query: () => ({
                url: '/sessions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedSessions = responseData.map(session => {
                    session.id = session._id
                    return session
                });
                return sessionsAdapter.setAll(initialState, loadedSessions)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Session', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Session', id }))
                    ]
                } else return [{ type: 'Session', id: 'LIST' }]
            }
        }),
        addNewSession: builder.mutation({
            query: initialSession => ({
                url: '/sessions',
                method: 'POST',
                body: {
                    ...initialSession,
                }
            }),
            invalidatesTags: [
                { type: 'Session', id: "LIST" }
            ]
        }),
        updateSession: builder.mutation({
            query: initialSession => ({
                url: '/sessions',
                method: 'PATCH',
                body: {
                    ...initialSession,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Session', id: arg.id }
            ]
        }),
        deleteSession: builder.mutation({
            query: ({ id }) => ({
                url: `/sessions`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Session', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetSessionsQuery,
    useAddNewSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
} = sessionsApiSlice

// returns the query result object
export const selectSessionsResult = sessionsApiSlice.endpoints.getSessions.select()

// creates memoized selector
const selectSessionsData = createSelector(
    selectSessionsResult,
    sessionsResult => sessionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSessions,
    selectById: selectSessionById,
    selectIds: selectSessionIds
    // Pass in a selector that returns the sessions slice of state
} = sessionsAdapter.getSelectors(state => selectSessionsData(state) ?? initialState)