import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import useAuth from '../hooks/useAuth'
import '../index.css';
import Logo2 from '../Favicon Transparent.ico';

const DashHeader = () => {
    //NEED THIS FOR ROLE BASED LOGIN
    const { isSVL } = useAuth()

    const navigate = useNavigate()

    const [sendLogout, {
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])


    const [barsOpen, setBarsOpen] = useState(false)
    const toggleNav = () => {
        setBarsOpen(!barsOpen)
    }

    const navBar = () => {
        if (barsOpen) {
            return "dash-header__nav"
        } else {
            return "dash-header__nav no-dash"
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <div className='header-container'>
            <div className="multi-header">
                <Link className="multi-header-logo" to="/dash"><img className="multi-header-logo" src={Logo2} alt="logo" /> </Link>
                <h1 className="vs-header-text">VOLLEYSUB</h1>
                </div>
                    <nav>
                    <button class="nav-toggle" onClick={toggleNav}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <ul className={`${navBar()}`}>
                        {!isSVL && <Link className='dash-header-links' to="/dash" onClick={toggleNav}>Home</Link>}
                        {!isSVL && <Link className='dash-header-links' to="/dash/leagues" onClick={toggleNav}>Leagues</Link>}
                        {!isSVL && <Link className='dash-header-links' to="/dash/subs" onClick={toggleNav}>Subs</Link>}
                        {isSVL && <Link className='dash-header-links' to="/dash/SVLHome" onClick={toggleNav}>Home</Link>}
                        {isSVL && <Link className='dash-header-links' to="/dash/sessions" onClick={toggleNav}>Sessions</Link>}
                        {!isSVL && <Link className='dash-header-links' to="/dash/profile" onClick={toggleNav}>Profile</Link>}
                        <Link className='dash-header-links' to="/" onClick={sendLogout}>Logout</Link>
                    </ul>
                    </nav>
            </div>
        </>
    )

    return content
}
export default DashHeader