import VSMultiHeader from '../../components/VSLoginHeader'
import VSMultiFooter from '../../components/VSLoginFooter'
import { useState } from 'react'
import '../../multiForms.css'
import { useNavigate } from 'react-router-dom'
import { useAddNewNewsletterMutation } from './newsletterApiSlice'

const ForgotPassword = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [referral, setReferral] = useState('')

    const [addNewNewsletter] = useAddNewNewsletterMutation()

    const onChangedEmail = e => setEmail(e.target.value)
    const onChangedReferral = e => setReferral(e.target.value)

    const handleSubmit = async (e) => {
            e.preventDefault()
            await addNewNewsletter({ email, referral })
            (navigate('/subscribed'))
    }


    return (
        <>
            <VSMultiHeader />
                <div className='forgotPasswordContainer'>
                <h1 className='PlayerSignUpTitle'>SIDE OUT Newsletter</h1>
                <form className="form" onSubmit={handleSubmit}>
                <h4>Enter your email to subcribe to Volleysub's weekly newsletter and stay up to date on our partner SVL's, Volleysub opportunities, and top stories from the volleyball world.</h4>
                <h5>Refer your friends for exclusive Volleysub rewards!</h5>
                    <label htmlFor="username">Email:
                    <input
                        className="form__input"
                        type="email"
                        id="email"
                        value={email}
                        onChange={onChangedEmail}
                        autoComplete="off"
                        required
                    />
                    </label>
                    <label htmlFor="referral">Referred by:
                    <input
                        className="form__input"
                        type="referral"
                        id="referral"
                        value={referral}
                        onChange={onChangedReferral}
                        autoComplete="off"
                    />
                    </label>
                    <button className="ForgotSubmitButton">Sumbit</button>
                </form>
            </div>
            <VSMultiFooter />
        </>
    )
}

export default ForgotPassword