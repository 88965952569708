import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from '../features/users/usersApiSlice'
import { memo } from 'react'

const SubTemplate = ({ userId }) => {

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })

    const navigate = useNavigate()

    if (user.sub) {
        const handleEdit = () => {
            sessionStorage.setItem("userKey", user.username)
            sessionStorage.setItem("email", user.email)
            navigate(`/dash/notes/newspecific`)
            
        }

        return (
            <>
            <tr className="sub-row">
                
                <td>{user.firstName}</td>
                <td >{user.lastName}</td>
                <td className='visible' >{user.gender}</td>
                <td>{user.hitterSkillLevel}</td>
                <td>{user.passerSkillLevel}</td>
                <td className='visible'>{user.setterSkillLevel}</td>
                <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Invite
                    </button>
                </td>
            </tr>
            </>
        )

    } else return null
}

const memoizedUser = memo(SubTemplate)

export default memoizedUser