import { useGetGamesQuery } from "./gameApiSlice"
import Game from "./Game"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from "react-router-dom"
import PulseLoader from 'react-spinners/PulseLoader'
import '../../SVLSide.css'
import '../../playerSide.css'



const GamesList = () => {
    useTitle('Volleysub: Schedule')

    const { username, isAdmin, isSVL } = useAuth()
    const navigate = useNavigate()
    const leagueName = sessionStorage.leagueName

    const {
        data: game,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetGamesQuery('gamesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    const leagueURL = window.location.pathname.slice(0, -15)
    
    const backToLeague = (e) => {
        e.preventDefault()
        navigate(leagueURL.toString())
    }

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = game

        let filteredIds
        if (true) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(gameId => entities[gameId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(gameId => <Game key={gameId} gameId={gameId} />)

        content = (
            <>
            <h2><span className='league-title'>Schedule - </span> {leagueName}</h2>
            <div className="league-links">
                {!isSVL && <p onClick={backToLeague}>Back to League</p>}
                {isSVL && <p onClick={backToLeague}>Back</p>}
            </div>
            <table>
            <div className="session-table-header-group">
                <thead>
                    <tr>
                        <th className="league-table-header" scope="col">Date</th>
                        <th className="league-table-header" scope="col">Matchup</th>
                        <th className="league-table-header" scope="col">Scores</th> 
                        {(isSVL || isAdmin) &&<th className="league-table-header" scope="col"></th>}
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </div>
            </table>
            </>
        )
    }
    
    return content
}
export default GamesList