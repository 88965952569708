import { useState, useEffect } from "react"
import { useUpdateNoteMutation, useDeleteNoteMutation } from "./notesApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import axios from "axios"

const EditNoteForm = ({ note }) => {

    const { username } = useAuth()

    const [updateNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateNoteMutation()

    const [deleteNote, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteNoteMutation()

    const navigate = useNavigate()

    const [requestor, setRequestor] = useState(note.requestor)
    const [requestorEmail] = useState(note.requestorEmail)
    const [recipient, setRecipient] = useState(note.recipient)
    const [recipientEmail] = useState(note.recipientEmail)
    const [status] = useState(note.status)
    const [team, setTeam] = useState(note.team)
    const [svl, setSvl] = useState(note.svl)
    const [date, setDate] = useState(note.date)
    const [text, setText] = useState(note.text)
    const [completed, setCompleted] = useState(note.completed)
    const [skillLevel, setSkillLevel] = useState(note.skillLevel)
    const [court, setCourt] = useState(note.court)
    const [format, setFormat] = useState(note.format)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setRequestor('')
            setRecipient('')
            setText('')
            setSvl('')
            setCompleted('')
            navigate('/dash/subs')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTextChanged = e => setText(e.target.value)
    const onTeamChanged = e => setTeam(e.target.value)
    const onSvlChanged = e => setSvl(e.target.value)
    const onDateChanged = e => setDate(e.target.value)
    const onCourtChanged = e => setCourt(e.target.value)
    const onSkillLevelChanged = e => setSkillLevel(e.target.value)
    const onFormatChanged = e => setFormat(e.target.value)

    const canSave = [svl, date, status, text, ].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        if (canSave) {
            await updateNote({ id: note.id, requestor, requestorEmail, recipient, recipientEmail, status, svl, team, date, court, skillLevel, format, text, completed })
        }
    }

    //email on accept
    const onAccepted = async (e) => {
        e.preventDefault()
        const formattedDate = date.toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit"})
        axios.post("https://volleysub-api.onrender.com/acceptedRequest", {
            requestorEmail: requestorEmail,
            recipient: username,
            date: formattedDate,
            svl: svl
    })
    await updateNote({ id: note.id, requestor, requestorEmail, recipient: username, recipientEmail, status: "Accepted", svl, team, date, court, skillLevel, format, text, completed })
    }

    const onDeclined = async (e) => {
        e.preventDefault()
        const formattedDate = date.toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit"})
        await updateNote({ id: note.id, requestor, requestorEmail, recipient, recipientEmail, status: "Declined", svl, team, date, court, skillLevel, format, text, completed })
        axios.post("https://volleysub-api.onrender.com/declinedRequest", {
            requestorEmail: requestorEmail,
            recipient: recipient,
            date: formattedDate,
            svl: svl
    })
    }

    const onDeleteNoteClicked = async () => {
        //add confirm popup
        const matchDate = new Date(note.date).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit"})
        if(window.confirm(`Are you sure you want to delete your sub request for ${team} on ${matchDate}?`) === true) {
        await deleteNote({ id: note.id })
        } else {
            return
        }
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    const matchDate = new Date(note.date).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit"})

    const acceptDecline = () => {
        if (note.status === "Open" && note.recipient === username) {
            return (
                <>
                <div className="sub-accept-decline">
                <button className="accept" onClick={onAccepted}>Accept</button>
                <button className="decline" onClick={onDeclined}>Decline</button>
                </div>
                </>
            )
        } else if(note.status === "Open" && note.recipient === "Anyone" && note.requestor !== username) {
            return (
                <>
                <div className="sub-accept-decline">
                <button
                className="accept"
                onClick={onAccepted}
                >
                Accept
                </button>
                </div>
                </>
            )
        }
    }

    let deleteButton = null
    if (username === note.requestor) {
        deleteButton = (
            <button
                title="Delete"
                onClick={onDeleteNoteClicked}
                className="delete-button"
            >
                Delete Sub Request
            </button>
        )
    }

    const content = (
        <>
            <p className={errClass}>{errContent}</p>
            <h2 className="league-title">Sub Request</h2>
            <p>Requestor: {note.requestor}</p>
            <p>Sub: {(note.recipient === "Anyone") ? "Open" : note.recipient}</p>
            <p>Status: {note.status}</p>
            <p>Date: {matchDate}</p>
            <p>Location: {note.svl}</p>
            <p>Court: {note.court}</p>
            <p>Team: {note.team}</p>
            <p>Skill: {note.skillLevel}</p>
            <p>Format: {note.format}</p>
            <p>Message: {note.text}</p>
            <p>{acceptDecline()}</p>
            {username === note.requestor && <details>
            <summary>Edit Sub Request</summary>
            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                </div>
                <label className="form__label" htmlFor="note-team">
                    Team:</label>
                <input
                    id="note-team"
                    name="team"
                    type="text"
                    autoComplete="off"
                    value={team}
                    onChange={onTeamChanged}
                />
                <label className="form__label" htmlFor="note-date">
                    Date/Time of Match:</label>
                <input
                    id="note-date"
                    name="date"
                    type="datetime-local"
                    autoComplete="off"
                    value={date}
                    onChange={onDateChanged}
                />
                <label className="form__label" htmlFor="note-svl">
                    Location:</label>
                <input
                    id="note-svl"
                    name="svl"
                    type="text"
                    autoComplete="off"
                    value={svl}
                    onChange={onSvlChanged}
                />
                <label className="form__label" htmlFor="note-court">
                    Court:</label>
                <input
                    id="note-court"
                    name="court"
                    type="text"
                    autoComplete="off"
                    value={court}
                    onChange={onCourtChanged}
                />
                <label className="form__label">
                    Skill Level:</label>
                <select
                    value={skillLevel}
                    onChange={onSkillLevelChanged}
                >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C+">C+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                </select>
                <label className="form__label">
                    Format:</label>
                <select
                    value={format}
                    onChange={onFormatChanged}
                >
                    <option value="doubles">Doubles</option>
                    <option value="quads">Quads</option>
                    <option value="5s">5s</option>
                    <option value="6s">6s</option>
                    <option value="reverseQuads">Reverse Quads</option>
                </select>
                
                <label className="form__label" htmlFor="note-text">
                    Text:</label>
                <textarea
                    id="note-text"
                    name="text"
                    value={text}
                    onChange={onTextChanged}
                />
            </form>
            <div className="edit-sub-button-container">
                <button
                    title="Save"
                    className="sub-button"
                    onClick={onSaveNoteClicked}
                    >
                    Update Sub Request
                    </button>
                {deleteButton}
            </div>
            </details>}
        </>
    )

    return content
}

export default EditNoteForm