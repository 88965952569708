import { useGetSessionsQuery } from "./sessionApiSlice"
import Session from "./Session"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { Link } from "react-router-dom"
import '../../SVLSide.css'

const SessionsList = ({ sessionId }) => {
    useTitle('Volleysub: Sessions')

    const { username, isSVL, isAdmin } = useAuth()

    const {
        data: session,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSessionsQuery('sessionsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })



    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = session

        let filteredIds
        if (isSVL || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(sessionId => entities[sessionId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(sessionId => <Session key={sessionId} sessionId={sessionId} />)

        content = (
            <>
            <h2 className="league-title">Sessions</h2>
            <div className="svl-links">
                <p><Link to="/dash/sessions/new">Add New Session</Link></p>
            </div>
            <table className="session-table-header-group">
                <thead>
                    <tr>
                        {isAdmin && <th className="session-table-header" scope="col">SVL</th>}
                        <th className="session-table-header" scope="col">Status</th>
                        <th className="session-table-header" scope="col">Name</th>
                        <th className="session-table-header" scope="col">Start Date</th>
                        <th className="session-table-header" scope="col">End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
            </>
        )
    }

        return content

}
export default SessionsList