import { useNavigate } from 'react-router-dom'
import { useGetSessionsQuery, useUpdateSessionMutation } from './sessionApiSlice'
import { memo, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import "../../SVLSide.css"

const Session = ({ sessionId }) => {

    const { username, isAdmin } = useAuth()

    const { session } = useGetSessionsQuery("sessionsList", {
        selectFromResult: ({ data }) => ({
            session: data?.entities[sessionId]
        }),
    })

    const [updateSession] = useUpdateSessionMutation()
    
    const updateStatus = async () => {
        if (session.status === "Inactive") {
            return
        } else if (new Date().toJSON() > session.endDateTime) {
            await updateSession({ id: sessionId, user: session.user, svl: session.svl, svlName:session.svlName, name: session.name, status: "Inactive", startDateTime: session.startDateTime, endDateTime: session.endDateTime })
        } else if (new Date().toJSON() > session.startDateTime) {
            await updateSession({ id: sessionId, user: session.user, svl: session.svl, svlName:session.svlName, name: session.name, status: "Active", startDateTime: session.startDateTime, endDateTime: session.endDateTime })
        }
    }

    useEffect(() => {
        updateStatus()
        // eslint-disable-next-line
    }, [])


    const navigate = useNavigate()

    const handleEdit = () => navigate(`/dash/sessions/${sessionId}`)
    const sessionStartDate = new Date(session.startDateTime).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' })
    const sessionEndDate = new Date(session.endDateTime).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' })


    if(isAdmin) {
        return (
            <>
            <tr className='session-row'>
                <td>{session.svl}</td>
                <td>{session.status}</td>
                <td>{session.name}</td>
                <td>{sessionStartDate}</td>
                <td>{sessionEndDate}</td>
                <td className="session-button">
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Select Session
                    </button>
                </td>
            </tr>
            </>
        )
    } else if(session.svl === username && session.status !== "Inactive") {
        return (
            <>
            <tr className='session-row'>
                <td>{session.status}</td>
                <td>{session.name}</td>
                <td>{sessionStartDate}</td>
                <td>{sessionEndDate}</td>
                <td className='session-button'>
                    <button
                        className="session-edit-button"
                        onClick={handleEdit}
                    >
                        Select Session
                    </button>
                </td>
            </tr>
            </>
        )
    }
}

const memoizedSession = memo(Session)

export default memoizedSession