import { useGetTeamsQuery } from "./TeamApiSlice"
import Team from "./Team"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import '../../SVLSide.css'


const TeamsList = () => {
    useTitle('Volleysub: Teams')

    const { isSVL, isAdmin } = useAuth()
    const navigate = useNavigate()

    const {
        data: team,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetTeamsQuery('teamsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content
    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    const leagueURL = window.location.pathname.slice(0, -6)
    
    const backToLeague = (e) => {
        e.preventDefault()
        navigate(leagueURL.toString())
    }

    if (isSuccess) {
        const { ids } = team
        let filteredIds
        if (isSVL || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids
        }

        const tableContent = ids?.length && filteredIds.map((teamId) => <Team key={teamId} teamId={teamId} />) 

        content = (
            <>
            <h2 className="league-title">Standings</h2>
            <div className="league-links">
                {!isSVL && <p onClick={backToLeague}>Back to League</p>}
                {isSVL && <p onClick={backToLeague}>Back</p>}
            </div>
            <table className="session-table-header-group">
                <thead>
                    <tr>
                        <th className="session-table-header" scope="col">Team</th>
                        <th className="session-table-header" scope="col">Wins</th>
                        <th className="session-table-header" scope="col">Losses</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
            </>
        )
    }

        return content

}
export default TeamsList