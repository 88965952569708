import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const gamesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = gamesAdapter.getInitialState()

export const gamesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGames: builder.query({
            query: () => ({
                url: '/games',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedGames = responseData.map(game => {
                    game.id = game._id
                    return game
                });
                return gamesAdapter.setAll(initialState, loadedGames)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Game', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Game', id }))
                    ]
                } else return [{ type: 'Game', id: 'LIST' }]
            }
        }),
        addNewGame: builder.mutation({
            query: initialGame => ({
                url: '/games',
                method: 'POST',
                body: {
                    ...initialGame,
                }
            }),
            invalidatesTags: [
                { type: 'Game', id: "LIST" }
            ]
        }),
        updateGame: builder.mutation({
            query: initialGame => ({
                url: '/games',
                method: 'PATCH',
                body: {
                    ...initialGame,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Game', id: arg.id }
            ]
        }),
        deleteGame: builder.mutation({
            query: ({ id }) => ({
                url: `/games`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Game', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetGamesQuery,
    useAddNewGameMutation,
    useUpdateGameMutation,
    useDeleteGameMutation,
} = gamesApiSlice

// returns the query result object
export const selectGamesResult = gamesApiSlice.endpoints.getGames.select()

// creates memoized selector
const selectGamesData = createSelector(
    selectGamesResult,
    gamesResult => gamesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllGames,
    selectById: selectGameById,
    selectIds: selectGameIds
    // Pass in a selector that returns the games slice of state
} = gamesAdapter.getSelectors(state => selectGamesData(state) ?? initialState)