import VSMultiHeader from './VSLoginHeader'
import VSMultiFooter from './VSLoginFooter'
import { useState } from 'react'
import '../multiForms.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const ForgotPassword = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')

    const onChangedEmail = e => setEmail(e.target.value)

    const handleSubmit = (e) => {
            e.preventDefault()
            axios.post("https://volleysub-api.onrender.com/send_recovery_email", {
                recipient_email: email
            }).then(res => {
                if (res.data.status === `Success`){
                navigate('/forgotPasswordSent')
                } else {
                    navigate('/forgotPasswordNotFound')
                }
            }).catch(error => console.log(error))
    }

    return (
        <>
            <VSMultiHeader />
                <div className='forgotPasswordContainer'>
                <h1 className='PlayerSignUpTitle'>Forgot Password</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Enter Email associated with Volleysub account
                    <input
                        className="form__input"
                        type="email"
                        id="email"
                        value={email}
                        onChange={onChangedEmail}
                        autoComplete="off"
                        required
                    />
                    </label>
                    <button className="ForgotSubmitButton">Sumbit</button>
                </form>
            </div>
            <VSMultiFooter />
        </>
    )
}

export default ForgotPassword