import React from "react";
import '../index.css';
import Logo2 from '../Favicon Transparent.ico';


const VSMultiFooter = () => {

    return(
        <div className="vs-multi-footer">
            <div className="vs-multi-footer-text">
                <div className="footer-copyright"><img className="multi-footer-logo" src={Logo2} alt="logo" />Volleysub, LLC © {(new Date().getFullYear())} </div>
            </div>
        </div>
    );
}

export default VSMultiFooter;