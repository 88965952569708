import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from '../features/users/usersApiSlice'
import { memo } from 'react'
import useAuth from '../hooks/useAuth'

const PlayerInfo = ({ userId }) => {
    const { username, isSVL, isAdmin } = useAuth()

    const {
        data: users
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const subInfo = () => {if(user.sub === false) {
        return "No"
    } else {
        return "Yes"
    }
}
    // eslint-disable-next-line
    const { ids } = users

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })


    const navigate = useNavigate()

    if (user.username === username) {
        const handleEdit = () => navigate(`/dash/users/${userId}`)

        return (
            <>
                <div className='player-info'>
                    <p>Username: {user.username}</p>
                    <p>Email: {user.email}</p>
                    <p>Name: {user.firstName}</p>
                    {(!isSVL) && <p>Last Name: {user.lastName}</p>}
                    {(isSVL || isAdmin) && <p>Address: {user.address}</p>}
                    {(isSVL || isAdmin) && <p>City: {user.city}</p>}
                    <p>State: {user.region}</p>
                    {(!isSVL) && <p>Gender: {user.gender}</p>}
                    {(!isSVL) && <p>Sub: {subInfo()}</p>}
                    {(!isSVL) && <p>Hitter Rating: {user.hitterSkillLevel}</p>}
                    {(!isSVL) && <p>Passer Rating: {user.passerSkillLevel}</p>}
                    {(!isSVL) && <p>Setter Rating: {user.setterSkillLevel}</p>}

                    <div className='info-edit-button'>
                        <button
                            className="session-update-button"
                            onClick={handleEdit}
                        >
                            Edit Info
                        </button>
                    </div>
                </div>
                </>
        )

    } else return null
}

const memoizedUser = memo(PlayerInfo)

export default memoizedUser